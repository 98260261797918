import { RoutePath } from "#utils/route";
import { RootRoute } from "#routes/root";

export const HOME_PAGE_TITLE = "Home";

export const HomeRoute = RootRoute.concat(
  new RoutePath({
    path: "/home",
    title: HOME_PAGE_TITLE,
  })
);
