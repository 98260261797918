import { WorkflowAdapter } from "@validereinc/domain";
import { useGetList, useGetOne } from "./adapterUtils";

/** TODO: All workflows should be on the `Resources.WORKFLOW` key */

export const useGetOneWorkflow = useGetOne(WorkflowAdapter.getOne, "workflows");

export const useListWorkflows = useGetList(
  WorkflowAdapter.getList,
  "workflows"
);
