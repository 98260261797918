import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns";
import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { FilterArea } from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { SavedFilterTag } from "#src/components/hooks/FilterPanel/useSavedFilters";
import { FACILITY_STATUS_OPTIONS } from "#src/constants";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useQueryClient } from "@tanstack/react-query";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  FacilityStatus,
  RecordDomain,
  ReportingGroupDomain,
  Resources,
  type GetListResponseType,
  type ReportingGroupType,
} from "@validereinc/domain";
import { sortReportingGroupsByPriority } from "@validereinc/domain-controllers/logic/reporting-groups";
import React from "react";

export const FacilityRecordsViewFilterArea = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const queryClient = useQueryClient();

  return (
    <FilterArea.Root<{
      reporting_group_id: string;
      date_range: { from: Date; to: Date };
    }>
      storageKey={viewConfigStorageKey}
      defaultValues={async () => {
        const reportingGroupsQueryFromCache = queryClient.getQueryData<
          GetListResponseType<ReportingGroupType>
        >([Resources.REPORTING_GROUP]);
        let defaultReportingGroup = "";

        if (reportingGroupsQueryFromCache?.data) {
          defaultReportingGroup =
            sortReportingGroupsByPriority(reportingGroupsQueryFromCache.data)[0]
              ?.id ?? "";
        } else {
          const reportingGroups = await ReportingGroupDomain.getList({});

          queryClient.setQueryData(
            [Resources.REPORTING_GROUP],
            reportingGroups
          );

          defaultReportingGroup =
            sortReportingGroupsByPriority(reportingGroups.data)[0]?.id ?? "";
        }

        return {
          reporting_group_id: defaultReportingGroup,
          date_range: DEFAULT_DATE_RANGES.lastWholeMonth,
        };
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="View Filters for Facility Records">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginBottom: 16,
              }}
            >
              <ReportingGroupFilterSwitcher
                name="reporting_group_id"
                onChange={(val) => handleOnChange(val, "reporting_group_id")}
              />
              <DateSelectorInput
                name="date_range"
                variant="month"
                isRange
                isInline
                onChange={(val) => handleOnChange(val, "date_range")}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const FacilityRecordsTableFilterAreaContent = ({
  selectableProperties,
}: {
  selectableProperties?: string[];
}) => {
  const { localize } = useLocalization();
  const { getTypeName } = useMeasurementTypes();
  const propertySelectorValues = selectableProperties?.map((key) => ({
    key,
    name: getTypeName(key),
  }));
  const { customAttributeFilters: facilityCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.FACILITY,
      prefix: "facility.custom_attributes",
    });

  return (
    <>
      <DropdownInput
        name="measurement_type"
        label="Measurement Type"
        options={propertySelectorValues}
        placeholder="Select Property..."
        labelKey="name"
        valueKey="key"
        isSearchable
        isOptionalTextShown={false}
      />
      <Accordion defaultActiveKeys={[AssetType.FACILITY]}>
        <Accordion.AccordionPanel
          dataKey={AssetType.FACILITY}
          title={localize(`${AssetType.FACILITY}_plural`)}
        >
          <FacilityDropdownInput
            isMulti
            isFluid
            name="facility.id"
            placeholder={`Select ${localize(`${AssetType.FACILITY}_plural`)}...`}
            isOptionalTextShown={false}
          />
          <DropdownInput
            name="facility.status"
            options={FACILITY_STATUS_OPTIONS}
            label={`${localize(AssetType.FACILITY)} Status`}
            placeholder="Select Status..."
            labelKey="label"
            valueKey="value"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          {facilityCustomAttributeFilters}
        </Accordion.AccordionPanel>
      </Accordion>
    </>
  );
};

export const FacilityRecordsTableFilterArea = ({
  filterConfigStorageKey,
  selectableProperties,
}: {
  selectableProperties?: string[];
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize } = useLocalization();

  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{
        "facility.status": [FacilityStatus.ACTIVE],
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="Filters for Facility Records">
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, marginBottom: 0 }}>
                <TextInput
                  name="facility.name"
                  label="Search"
                  isLabelShown={false}
                  placeholder={`Search ${localize(`${AssetType.FACILITY}_plural`)}...`}
                  type="search"
                  isInline
                  onChange={(val) => handleOnChange(val, "facility.name")}
                />
              </div>
            )}
          </FilterArea.Content>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <FilterDrawer.SavedFilters
              config={{
                resourceType: Resources.RECORD,
                savedFilterResourceAdapter: RecordDomain.savedFilters,
                tag: SavedFilterTag.FACILITY_RECORDS,
                filterBlacklist: ["date_range.from", "date_range.to"],
              }}
            />
            <FilterDrawer.SavedFiltersAppliedIndicator />
            <FacilityRecordsTableFilterAreaContent
              selectableProperties={selectableProperties}
            />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
