import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import Map from "#components/Map/Map";
import Marker from "#components/Map/Marker/Marker";
import { useParams } from "#src/Routers/hooks";
import { AssociatedFlowTablePanel } from "#src/batteries-included-components";
import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useQuery } from "@tanstack/react-query";
import { Link, Panel } from "@validereinc/common-components";
import {
  EquipmentType,
  Resources,
  TemplatedConfigurationRunAdapter,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { type ReactNode, useState } from "react";
import EquipmentDetailsPanel from "./DetailsTab";
import styles from "./EquipmentDetailOverviewTab.module.scss";

const cx = classNames.bind(styles);

const EquipmentDetailOverviewTab = ({
  equipmentDetail,
  viewFilterAreaSlot,
}: {
  equipmentDetail: EquipmentType;
  filterKey: string;
  viewFilterAreaSlot: ReactNode;
}) => {
  const { equipmentId } = useParams<{
    equipmentId: string;
  }>();
  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      equipmentId,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: equipmentId,
      }),
    enabled: isTemplatedConfigsAvailable && !!equipmentId,
  });

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const initialViewState = {
    latitude: equipmentDetail?.latitude,
    longitude: equipmentDetail?.longitude,
  };

  const sharedProps = {
    type: "vertical",
    allowWrap: true,
    isStatusPanel: true,
  };

  const shouldDisplayMap =
    (equipmentDetail?.latitude || equipmentDetail?.latitude === 0) &&
    (equipmentDetail?.longitude || equipmentDetail?.longitude === 0);

  if (!equipmentDetail) {
    return null;
  }

  return (
    <>
      {viewFilterAreaSlot}
      <div className={cx("overviewAndMapSection")}>
        <Panel isFluidY={false}>
          <SummaryInformationContainer
            variant="vertical"
            status={equipmentDetail?.status}
            statusVariant={equipmentDetail?.status}
          >
            <SummaryInformation
              {...sharedProps}
              title="Created At"
              value={datetimeFormatter(new Date(equipmentDetail.created_at))}
            />
            <SummaryInformation
              {...sharedProps}
              title="Updated At"
              value={datetimeFormatter(new Date(equipmentDetail.updated_at))}
            />
            {templatedConfigurationRunDependencyGraph ? (
              <SummaryInformation
                {...sharedProps}
                title="Configuration Template"
                value={
                  <Link
                    underline="none"
                    onClick={() =>
                      setIsTemplatedConfigurationRunDrawerOpen(true)
                    }
                    label={templatedConfigurationRunDependencyGraph.name}
                  />
                }
              />
            ) : null}
          </SummaryInformationContainer>
        </Panel>
        <EquipmentDetailsPanel
          equipmentDetail={equipmentDetail}
          isMapDisplayed={shouldDisplayMap}
        />

        {shouldDisplayMap ? (
          <Map
            initialViewState={initialViewState}
            subtractFromHeightOnResize={16}
          >
            <Marker
              latitude={initialViewState.latitude}
              longitude={initialViewState.longitude}
              iconType="broadcast"
              size="large"
              label={equipmentDetail.name}
            />
          </Map>
        ) : null}
      </div>

      <IsFeatureAvailable
        featureFlagQuery="core:flows"
        permissionQuery="flows:read"
        fallbackChildren={null}
      >
        <AssociatedFlowTablePanel
          id={equipmentDetail.id}
          type="equipment"
        />
      </IsFeatureAvailable>
      <IsFeatureAvailable
        featureFlagQuery="core:templated_configurations"
        permissionQuery="templated_configurations:read"
        fallbackChildren={null}
      >
        <TemplatedConfigurationRunDrawer
          isOpen={isTemplatedConfigurationRunDrawerOpen}
          onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
          templatedConfigurationRunDependencyGraph={
            templatedConfigurationRunDependencyGraph
          }
          resourceId={equipmentId}
        />
      </IsFeatureAvailable>
    </>
  );
};

export default EquipmentDetailOverviewTab;
