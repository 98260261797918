import CustomAttributeField from "#src/components/Common/CustomAttributeField";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { getArrayAdditionsAndExclusions } from "#src/utils/arrayFormatter";
import {
  Button,
  CheckboxInput,
  DateSelectorInput,
  Dialog,
  DropdownInput,
  Form,
  GeoPointInput,
  Icon,
  RadioInput,
  useForm,
  usePrevious,
} from "@validereinc/common-components";
import { AssetType, EquipmentType, FlowType } from "@validereinc/domain";
import {
  parseAsLatitudeDegree,
  parseAsLongitudeDegree,
  yearMonthFormatter,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import startOfMonth from "date-fns/startOfMonth";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./AssetEditDialog.module.scss";
import { AssetEditDialogPropTypes } from "./types";

const cx = classNames.bind(styles);

type FormValues = {
  // Utility form values; These keys are not sent to the API:
  hasDateRange: boolean;
  hasDateRangeStart?: boolean;
  hasDateRangeEnd?: boolean;
  dateRange:
    | {
        from?: string | Date;
        to?: string | Date;
      }
    | undefined;
} & {
  geo: [lat?: string, long?: string];
} & Record<string, any>; // Default values of the asset when dialog is opened:

const AttributeNotStateManagedBanner = ({
  display_name,
}: {
  display_name: string;
}) => (
  <div className={cx("banner-container")}>
    <div className={cx("icon")}>
      <Icon variant="warning" />
    </div>
    <div className={cx("description")}>
      {`The value for “${display_name}” will apply to all time and cannot be changed for a specific date range.`}
    </div>
  </div>
);

const AssetEditDialog = (props: AssetEditDialogPropTypes) => {
  const {
    assetId,
    onClose,
    isLoading: isLoadingProp,
    primaryFields,
    readOnlyFields,
    assetType,
    onUpdate,
    assetDetails,
    dialogProps,
    hasGeoPointField,
  } = props;

  const form = useForm<FormValues>({
    defaultValues: {
      hasDateRange: false,
    },
  });
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);

  const customAttributesQuery = useListCustomAttributes(
    { filters: { entity_type: assetType } },
    {
      enabled: !!assetId,
    }
  );

  const customAttributes = customAttributesQuery.data?.data ?? [];

  // Filter custom attributes by their entity_subtype (=== asset.type.id) before rendering:
  const relevantCustomAttributes = useMemo(() => {
    if (
      assetType === AssetType.EQUIPMENT &&
      (assetDetails as EquipmentType)?.type_id
    ) {
      return customAttributes.filter(
        (customAttribute) =>
          customAttribute.entity_subtype ===
            (assetDetails as EquipmentType).type_id ||
          !customAttribute.entity_subtype
      );
    }
    if (assetType === AssetType.FLOW && (assetDetails as FlowType)?.type) {
      return customAttributes.filter(
        (customAttribute) =>
          customAttribute.entity_subtype === (assetDetails as FlowType)?.type ||
          !customAttribute.entity_subtype
      );
    }
    return customAttributes;
  }, [customAttributes, assetDetails, assetType]);

  const { hasDateRange, hasDateRangeStart, hasDateRangeEnd, dateRange } =
    form.watch();

  const hasDateRangeStartAndEnd =
    hasDateRange && hasDateRangeStart && hasDateRangeEnd;

  // when popup opens
  useEffect(() => {
    if (!assetId) return;
    setSelectedAttributes([]);
    form.reset();
  }, [assetId]);

  // Remove dateRange from form values when start or end checkboxes change:
  useEffect(() => {
    form.setValue("dateRange", undefined);
  }, [hasDateRangeStart, hasDateRangeEnd]);

  // Remove checkbox states when changing date range radio:
  useEffect(() => {
    if (!hasDateRange) {
      form.setValue("hasDateRangeStart", undefined);
      form.setValue("hasDateRangeEnd", undefined);
    }
  }, [hasDateRange]);

  // If an attribute gets selected and gets a value and then removed from the selected attributes, its value should be wiped.
  // Also when an attribute is added (primary or custom attribute), its value should be prepopulated:
  const previousSelectedAttributes = usePrevious(selectedAttributes) ?? [];
  useEffect(() => {
    const diff = getArrayAdditionsAndExclusions(
      previousSelectedAttributes,
      selectedAttributes
    );
    diff.removed.forEach((removedAttributes) => {
      form.setValue(removedAttributes, undefined);
    });
    diff.added.forEach((addedAttributes) => {
      const valueFromDetails =
        get(assetDetails, addedAttributes) ??
        get(assetDetails, `attributes.${addedAttributes}`) ??
        get(assetDetails, `custom_attributes.${addedAttributes}`);
      if (valueFromDetails !== null || valueFromDetails !== undefined)
        form.setValue(addedAttributes, valueFromDetails);
    });
  }, [selectedAttributes, assetDetails]);

  const isLoading =
    !!isLoadingProp || (customAttributesQuery.isLoading && !!assetId);

  const sharedAttributeInputProps = {
    isDisabled: isLoading,
  };

  const selectableAttributesToEdit = [
    ...primaryFields,
    /* IMPROVEMENT: For now, only one Geo point input is available in primary fields: */
    ...(hasGeoPointField
      ? [
          {
            display_name: "Latitude & Longitude",
            field_name: "geo",
            customRenderer: () => (
              <GeoPointInput
                {...sharedAttributeInputProps}
                name="geo"
              />
            ),
          },
        ]
      : []),
    ...relevantCustomAttributes.map((custom_attribute) => {
      return {
        ...custom_attribute,
        field_name: `custom_attributes.${custom_attribute.field_name}`,
      } as typeof custom_attribute;
    }),
  ];

  const isSaveButtonDisabled =
    selectedAttributes.length === 0 ||
    (hasDateRange && !hasDateRangeStart && !hasDateRangeEnd) ||
    ((hasDateRangeStart || hasDateRangeEnd) && !dateRange) ||
    sharedAttributeInputProps.isDisabled ||
    isLoading;

  const rangePickerLabel = hasDateRangeStartAndEnd
    ? "Date Range"
    : hasDateRangeStart
      ? "Start Period"
      : hasDateRangeEnd
        ? "End Period"
        : "";

  const handleSubmit = form.handleSubmit((values) => {
    const allowedFields = [
      ...primaryFields.map((primaryField) => primaryField.field_name),
      "custom_attributes",
      "attributes",
    ];

    const refinedValues = pick(values, ...allowedFields);

    if (values?.geo) {
      refinedValues.latitude =
        parseAsLatitudeDegree(String(values?.geo?.[0])) ?? undefined;
      refinedValues.longitude =
        parseAsLongitudeDegree(String(values?.geo?.[1])) ?? undefined;
    }

    if (values?.type) {
      refinedValues.type_id = values.type.id;
    }

    refinedValues.start_date = "min";
    refinedValues.end_date = "max";

    if (hasDateRange) {
      const { from: dateRangeFrom, to: dateRangeTo } = {
        from: "",
        to: "",
        ...dateRange,
      };
      const startDate = new Date(dateRangeFrom);
      const endDate = new Date(dateRangeTo);

      if (hasDateRangeStart) {
        refinedValues.start_date = yearMonthFormatter(startDate);
      }

      if (hasDateRangeEnd) {
        refinedValues.end_date = hasDateRangeStart
          ? yearMonthFormatter(startOfMonth(endDate))
          : yearMonthFormatter(startDate);
      }
    }

    // Do a cleanup on attributes and custom_attributes to remove any "undefined" values,
    // and get rid of them if result in a completely empty object.
    if (refinedValues.attributes) {
      refinedValues.attributes = pickBy(
        refinedValues.attributes,
        (value) => value !== undefined
      );
      if (isEmpty(refinedValues.attributes)) delete refinedValues.attributes;
    }
    if (refinedValues.custom_attributes) {
      refinedValues.custom_attributes = pickBy(
        refinedValues.custom_attributes,
        (value) => value !== undefined
      );
      if (isEmpty(refinedValues.custom_attributes))
        delete refinedValues.custom_attributes;
    }

    if (!assetId) return;

    onUpdate(refinedValues);
  });

  return (
    <Form
      {...form}
      onSubmit={handleSubmit}
    >
      <Dialog
        key="asset-edit-dialog"
        title="Edit Asset Attributes"
        isOpen={!!assetId}
        onClose={onClose}
        actionRow={[
          <Button
            key="asset-edit-dialog-save-button"
            variant="primary"
            disabled={isSaveButtonDisabled}
            onClick={handleSubmit}
          >
            Save
          </Button>,
        ]}
        {...dialogProps}
      >
        <p>
          Please select the attributes you wish to edit and specify the date
          range for which the values should be applied.
        </p>

        <div className={cx("section-container")}>
          <section>
            <span className={cx("title")}>Attribute Selection</span>
            <DropdownInput
              key={"attribute_selector"}
              inputId={"attribute_selector"}
              name={"attribute_selector"}
              options={selectableAttributesToEdit}
              isLoading={isLoading}
              labelKey="display_name"
              valueKey="field_name"
              placeholder="Select attributes..."
              isFluid
              isMulti
              label="Attributes to Edit"
              isRequired
              onChange={(selected: string[]) => {
                setSelectedAttributes(selected);
              }}
              value={selectedAttributes}
            />
          </section>

          {Array.isArray(readOnlyFields) && readOnlyFields.length > 0 && (
            <section>
              <span className={cx("title")}>Unchangeable Values</span>
              <div className={cx("readonly-container")}>
                {readOnlyFields.map((readOnlyField) => (
                  <div
                    key={readOnlyField.field_name}
                    className={cx("readonly-wrapper")}
                  >
                    <Icon variant="lock" />
                    <b>{readOnlyField.display_name}: </b>
                    {readOnlyField.value}
                  </div>
                ))}
              </div>
            </section>
          )}

          {selectedAttributes.length > 0 && (
            <section>
              <span className={cx("title")}>Attribute Values</span>
              {selectableAttributesToEdit
                .filter(
                  (attribute) =>
                    selectedAttributes.includes(
                      `custom_attributes.${attribute.field_name}`
                    ) || selectedAttributes.includes(attribute.field_name)
                )
                .map(
                  ({
                    field_name,
                    display_name,
                    customRenderer,
                    is_state_managed = true,
                    ...restAttribute
                  }: {
                    field_name: string;
                    is_state_managed?: boolean;
                    display_name: string;
                    customRenderer?: (
                      propsObject: Record<string, any>
                    ) => React.ReactElement;
                  }) => (
                    <>
                      {customRenderer?.(sharedAttributeInputProps) ?? (
                        <CustomAttributeField
                          {...sharedAttributeInputProps}
                          key={field_name}
                          name={field_name}
                          attribute={{
                            field_name,
                            display_name,
                            ...restAttribute,
                          }}
                          subtype={assetDetails?.type?.id}
                        />
                      )}
                      {!is_state_managed && (
                        <AttributeNotStateManagedBanner
                          display_name={display_name}
                        />
                      )}
                    </>
                  )
                )}
            </section>
          )}

          {selectedAttributes.length > 0 && (
            <section>
              <span className={cx("title")}>Date Range</span>
              <RadioInput
                {...sharedAttributeInputProps}
                name="hasDateRange"
                inputId="radio"
                labelKey="label"
                valueKey="value"
                options={[
                  { label: "Set values for all time", value: false },
                  { label: "Define range for values", value: true },
                ]}
                className={cx("radio-container")}
              />
              {hasDateRange && (
                <>
                  <div className={cx("checkbox-container")}>
                    <CheckboxInput
                      {...sharedAttributeInputProps}
                      name="hasDateRangeStart"
                      label="Define Start Period"
                      isLabelShown={false}
                      isFluid
                      className={cx("checkbox")}
                    />
                    <CheckboxInput
                      {...sharedAttributeInputProps}
                      name="hasDateRangeEnd"
                      label="Define End Period"
                      isLabelShown={false}
                      isFluid
                      className={cx("checkbox")}
                    />
                  </div>
                  {(hasDateRangeStart || hasDateRangeEnd) && (
                    <DateSelectorInput
                      {...sharedAttributeInputProps}
                      variant="month"
                      isRange={!!hasDateRangeStartAndEnd}
                      name="dateRange"
                      label={rangePickerLabel}
                      isFluid
                      isRequired
                      hasNextPrevButtons={!hasDateRangeStartAndEnd}
                    />
                  )}
                </>
              )}
            </section>
          )}
        </div>
      </Dialog>
    </Form>
  );
};

export default AssetEditDialog;
