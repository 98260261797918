import { useNavigate } from "#src/Routers/hooks";
import { Button, EmptyState, Icon } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "./NotFound.module.scss";
import { HomeRoute } from "#src/routes/home";

const cx = classNames.bind(styles);

/**
 * When the user navigates to an invalid route in the app, when authenticated,
 * this layout is shown.
 */
export const NotFoundLayout = () => {
  const navigate = useNavigate();

  return (
    <EmptyState
      icon={<Icon variant="file-x" />}
      title="Page not found."
      suggestion=""
      className={cx("container")}
      variant="error"
      action={
        <Button
          variant="primary"
          onClick={() => {
            navigate({
              pathname: HomeRoute.path,
            });
          }}
        >
          Go to Homepage
        </Button>
      }
    />
  );
};
