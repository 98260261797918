import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import {
  CreateUserDetailsStep,
  UserCreateFormType,
} from "#src/batteries-included-components/Panels/FormPanels/CreateUserFormPanel";
import { useIsOpsHubExperience } from "#src/contexts/AuthenticatedContext.helpers";
import { SETTINGS_BREADCRUMB } from "#src/routes/settings";
import {
  USERS_LIST_BREADCRUMB,
  linkToUsersListPage,
} from "#src/routes/settings/users";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  RolesAdapter,
  UserCreateSchema,
  UsersAdapter,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React from "react";
import { CREATE_USER_BREADCRUMB, CREATE_USER_PAGE_TITLE } from ".";
import styles from "./CreateUserPage.module.scss";

const cx = classNames.bind(styles);

export const CreateUserPageContent = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const form = useForm<UserCreateFormType>();
  const queryClient = useQueryClient();

  const breadcrumbs = useBreadcrumbs([
    SETTINGS_BREADCRUMB,
    USERS_LIST_BREADCRUMB,
    CREATE_USER_BREADCRUMB,
  ]);

  const onCancelClick = () => {
    navigate({
      pathname: linkToUsersListPage(),
    });
  };

  const createUserMutation = useMutation({
    mutationFn: async ({
      name,
      email,
      phone = "",
      timezone,
      roles,
      quicksight = false,
    }: UserCreateFormType) => {
      const parseResult = UserCreateSchema.safeParse({
        name,
        email,
        phone,
        timezone,
        quicksight,
      });

      if (!parseResult.success) {
        throw new Error("The form was not filled out correctly.");
      }

      const createdUser = await UsersAdapter.createOne({
        data: {
          name,
          email,
          timezone,
          quicksight,
        },
      });

      if (roles?.length) {
        if (!createdUser.data.id) {
          throw new Error(
            "Created user details not available to assign roles."
          );
        }

        await Promise.all(
          roles.map((roleId) =>
            RolesAdapter.members.update({
              roleId: roleId,
              userId: createdUser.data.id,
            })
          )
        );
      }

      return createdUser;
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to create user.",
      });
    },
    onSuccess: (createdUser) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      addAlert?.({
        variant: "success",
        message: `Successfully created user: ${createdUser.data.name}.`,
      });
      navigate({
        pathname: linkToUsersListPage(),
      });
    },
  });

  const onSubmit = form.handleSubmit((values) => {
    return createUserMutation.mutateAsync(values);
  });

  const isLoading = createUserMutation.isLoading;

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={CREATE_USER_PAGE_TITLE}
      footer={
        <div className={cx("footerContainer")}>
          <Button
            disabled={isLoading}
            onClick={onCancelClick}
          >
            Cancel
          </Button>

          <div className={cx("stepperFooterContainer")}>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={!form.formState.isValid}
              isLoading={isLoading}
            >
              Create
            </Button>
          </div>
        </div>
      }
    >
      <Form {...form}>
        <CreateUserDetailsStep isLoading={isLoading} />
      </Form>
    </Page>
  );
};

export const CreateUserPage = () => {
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  if (isOpsExperienceEnabled) {
    return <AccessDeniedLayout />;
  }

  return <CreateUserPageContent />;
};
