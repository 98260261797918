import {
  AssetTypeOptions,
  AssetTypeSelection,
  AssetTypeSelectionType,
  useAssetDropdownInput,
  useAssetSearchInput,
} from "#src/batteries-included-components/FilterAreas/assetFilters.helpers";
import WorkflowTaskUserGroupDropdown from "#src/batteries-included-components/Filters/WorkflowTaskUserGroupDropdown/WorkflowTaskUserGroupDropdown";
import {
  FilterArea,
  useFilterAreaContentContext,
  useFilterAreaContext,
} from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { convertMapToOpts } from "#src/constants";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  FilterPills,
  FilterPillVariants,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import {
  WorkflowCategoriesAdapter,
  WorkflowStepType,
  WorkflowTaskBaseSchema,
  WorkflowTaskStatus,
  WorkflowTaskTypes,
  WorkflowTemplatesAdapter,
} from "@validereinc/domain";
import { asUTC } from "@validereinc/utilities";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import startCase from "lodash/startCase";
import React, { useMemo } from "react";

export const FilterConfig = {
  search: {
    name: WorkflowTaskBaseSchema.keyof().Enum.name,
    placeholder: "Search Tasks...",
  },
  status: {
    name: WorkflowTaskBaseSchema.keyof().Enum.status,
  },
  assetName: {
    name: "assetName",
  },
  facility: {
    name: "facilityId",
  },
  equipment: {
    name: "equipmentId",
  },
  device: {
    name: "deviceId",
  },
  flow: {
    name: "flowId",
  },
  asset_group: {
    name: "assetGroupId",
  },
  assetType: {
    name: "assetType",
  },
  dueDate: {
    name: "workflow.due_date",
  },
  assignee_type: {
    name: "assignee_type",
  },
  assignee: {
    name: "assignee",
  },
  workflowCategory: {
    name: "categoryId",
  },
  workflowTemplate: {
    name: "templateId",
  },
  stepType: {
    name: WorkflowTaskBaseSchema.keyof().Enum.step_type,
  },
  type: {
    name: WorkflowTaskBaseSchema.keyof().Enum.type,
  },
} as const;

export type WorkflowTasksTableFiltersType = {
  [FilterConfig.search.name]: string;
  [FilterConfig.status.name]: string;
  [FilterConfig.dueDate.name]: { from: string; to: string };
  [FilterConfig.workflowCategory.name]?: string;
  [FilterConfig.stepType.name]?: string;
  [FilterConfig.type.name]?: string;
  workflow: Record<string, any>;
};

export type WorkflowTasksViewFiltersType = {
  [FilterConfig.assetType.name]: AssetTypeSelectionType;
  [FilterConfig.workflowTemplate.name]?: string;
  [FilterConfig.facility.name]: string[];
  [FilterConfig.equipment.name]: string[];
  [FilterConfig.device.name]: string[];
  [FilterConfig.flow.name]: string[];
  [FilterConfig.asset_group.name]: string[];
  [FilterConfig.assignee_type.name]?: string;
  [FilterConfig.assignee.name]?: string;
};

export const useRelativeDateRange = (dateRangeInDays = 30) =>
  useMemo(
    () => ({
      from: asUTC(startOfDay(subDays(new Date(), dateRangeInDays))),
      to: asUTC(endOfDay(addDays(new Date(), dateRangeInDays))),
    }),
    []
  );

export const WorkflowTasksViewFilterAreaContent = ({
  viewConfigStorageKey,
  defaultAssetType,
  showAssigneeFilter,
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  defaultAssetType?: AssetTypeSelectionType;
  showAssigneeFilter?: boolean;
}) => {
  const { handleOnChange } = useFilterAreaContentContext();
  const { localize } = useLocalization();
  const [filters] = useSessionStickyState<WorkflowTasksViewFiltersType>(
    {} as WorkflowTasksViewFiltersType,
    viewConfigStorageKey
  );
  const assetType = filters.assetType ?? defaultAssetType;

  const assetSelectionInput = useAssetDropdownInput(
    assetType,
    FilterConfig[assetType as keyof typeof FilterConfig]?.name,
    {
      isLabelShown: false,
      isFluid: false,
      isInline: true,
      description: `Filter by ${assetType === AssetTypeSelection.ALL_ASSETS ? "all assets" : `${localize(`${assetType}_plural`)}`}`,
      onChange: (val) =>
        handleOnChange(
          val,
          FilterConfig[assetType as keyof typeof FilterConfig]?.name
        ),
    }
  );

  return (
    <>
      <DropdownInput
        name={FilterConfig.assetType.name}
        isClearable={false}
        isInline
        labelKey="label"
        valueKey="value"
        placeholder={"Select Asset Type..."}
        options={AssetTypeOptions}
        isSortedAlphabetically={false}
        description="Filter by Asset Type"
        onChange={(val) => handleOnChange(val, FilterConfig.assetType.name)}
      />
      {assetSelectionInput}
      <DropdownInput
        name={FilterConfig.workflowTemplate.name}
        onFetchData={async (payload) => {
          let { data } = await WorkflowTemplatesAdapter.getList({
            ...payload,
            filters: {
              ...(payload.searchTerm
                ? {
                    name: payload.searchTerm,
                  }
                : {}),
            },
          });

          // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
          if (Array.isArray(payload.value)) {
            data = data.filter((d) => payload.value.includes(d.id));
          }

          return data;
        }}
        labelKey="name"
        valueKey="id"
        placeholder="Select Template..."
        isMulti
        label="Workflow Template"
        description="Filter by Workflow Template"
        isLabelShown={false}
        isOptionalTextShown={false}
        isInline
        onChange={(val) =>
          handleOnChange(val, FilterConfig.workflowTemplate.name)
        }
      />
      {showAssigneeFilter ? (
        <WorkflowTaskUserGroupDropdown
          name={FilterConfig.assignee.name}
          placeholder="Select Assignee..."
          label="Assignee"
          isOptionalTextShown={false}
          viewConfigStorageKey={viewConfigStorageKey}
          isInline
          onChange={(val) => handleOnChange(val, FilterConfig.assignee.name)}
        />
      ) : null}
    </>
  );
};

export const WorkflowTasksViewFilterArea = ({
  viewConfigStorageKey,
  defaultAssetType = AssetTypeSelection.FACILITY,
  ...rest
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  defaultAssetType?: AssetTypeSelectionType;
  showAssigneeFilter?: boolean;
}) => {
  return (
    <FilterArea.Root
      storageKey={viewConfigStorageKey}
      defaultValues={{
        [FilterConfig.assetType.name]: defaultAssetType,
        assignee_type: "users",
      }}
      applyDefaultValues
    >
      <FilterArea.Container style={{ marginBottom: 16 }}>
        <FilterArea.Content>
          {() => (
            <div
              style={{
                display: "flex",
                gap: 8,
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <WorkflowTasksViewFilterAreaContent
                viewConfigStorageKey={viewConfigStorageKey}
                defaultAssetType={defaultAssetType}
                {...rest}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

const WorkflowTasksTableFilterAreaDrawerContent = ({
  viewConfigStorageKey,
  defaultAssetType,
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  defaultAssetType: AssetTypeSelectionType;
}) => {
  const { localize } = useLocalization();
  const [filters] = useSessionStickyState<WorkflowTasksViewFiltersType>(
    {} as WorkflowTasksViewFiltersType,
    viewConfigStorageKey
  );
  const assetType = filters.assetType ?? defaultAssetType;

  const attributeFilters = useCustomAttributeFiltersV2({
    assetType:
      assetType !== AssetTypeSelection.ALL_ASSETS
        ? assetType
        : AssetTypeSelection.FACILITY,
    prefix: `workflow.${assetType}.custom_attributes`,
  });

  return (
    <>
      <DateSelectorInput
        {...FilterConfig.dueDate}
        variant="day"
        isRange
        isFluid
        label="Due Date"
        isOptionalTextShown={false}
        description="Search based on Workflow Due Date"
      />
      <DropdownInput
        name={FilterConfig.workflowCategory.name}
        onFetchData={async (payload) => {
          let { data } = await WorkflowCategoriesAdapter.getList({
            ...payload,
            filters: {
              ...(payload.searchTerm
                ? {
                    name: payload.searchTerm,
                  }
                : {}),
            },
          });

          // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
          if (Array.isArray(payload.value)) {
            data = data.filter((d) => payload.value.includes(d.id));
          }
          return data;
        }}
        labelKey="name"
        valueKey="id"
        placeholder="Select Category..."
        isMulti
        isFluid
        label="Workflow Category"
        isOptionalTextShown={false}
      />
      {assetType !== "all_assets" ? (
        <Accordion defaultActiveKeys={[assetType]}>
          <Accordion.AccordionPanel
            dataKey={assetType}
            title={localize(`${assetType}_plural`)}
          >
            {...attributeFilters.customAttributeFilters}
          </Accordion.AccordionPanel>
        </Accordion>
      ) : null}
    </>
  );
};

const WorkflowTasksSingleWorkflowTableFilterAreaDrawerContent = () => {
  return (
    <>
      <DropdownInput
        name={FilterConfig.stepType.name}
        label="Action Step Type"
        placeholder="Select Type..."
        options={convertMapToOpts(WorkflowStepType, ([_, value]) =>
          startCase(value)
        )}
        labelKey="label"
        valueKey="value"
      />
      <DropdownInput
        name={FilterConfig.type.name}
        label="Action Type"
        placeholder="Select Type..."
        options={convertMapToOpts(WorkflowTaskTypes, ([_, value]) =>
          startCase(value)
        )}
        labelKey="label"
        valueKey="value"
      />
    </>
  );
};

const WorkflowTasksStatusFilterPills = ({
  onChange,
}: {
  onChange: (val: string) => void;
}) => {
  const { storedFilters } = useFilterAreaContext<{ status: string }>();
  const filterPills = [
    {
      name: "All",
      label: "All",
      value: null,
      isSelected: !storedFilters.status,
    },
    {
      name: WorkflowTaskStatus.OPEN,
      label: startCase(WorkflowTaskStatus.OPEN),
      value: WorkflowTaskStatus.OPEN,
      variant: FilterPillVariants.PENDING,
      isSelected: storedFilters.status === WorkflowTaskStatus.OPEN,
    },
    {
      name: WorkflowTaskStatus.COMPLETE,
      label: startCase(WorkflowTaskStatus.COMPLETE),
      value: WorkflowTaskStatus.COMPLETE,
      variant: FilterPillVariants.GOOD,
      isSelected: storedFilters.status === WorkflowTaskStatus.COMPLETE,
    },
    {
      name: WorkflowTaskStatus.DISMISSED,
      label: startCase(WorkflowTaskStatus.DISMISSED),
      value: WorkflowTaskStatus.DISMISSED,
      variant: FilterPillVariants.NEUTRAL,
      isSelected: storedFilters.status === WorkflowTaskStatus.DISMISSED,
    },
  ];

  return (
    <FilterPills
      name="status"
      pills={filterPills}
      onChange={(val) => onChange(val)}
    />
  );
};

export const WorkflowTasksTableTitleDecorationFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container aria-label="Filters for Workflow Tasks">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div style={{ marginRight: 8, display: "flex", gap: 8 }}>
              <WorkflowTasksStatusFilterPills
                onChange={(val) =>
                  handleOnChange(val, FilterConfig.status.name)
                }
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const WorkflowTasksTableFilterArea = ({
  filterConfigStorageKey,
  viewConfigStorageKey,
  defaultAssetType = AssetTypeSelection.FACILITY,
  isWithinAWorkflow = false,
}: Pick<StorageKeys, "filterConfigStorageKey" | "viewConfigStorageKey"> & {
  defaultAssetType?: AssetTypeSelectionType;
  isWithinAWorkflow?: boolean;
}) => {
  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container aria-label="Filters for Workflow Tasks">
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, display: "flex", gap: 8 }}>
                <TextInput
                  name={FilterConfig.search.name}
                  placeholder={FilterConfig.search.placeholder}
                  type="search"
                  isInline
                  onChange={(val) =>
                    handleOnChange(val, FilterConfig.search.name)
                  }
                />
              </div>
            )}
          </FilterArea.Content>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            {isWithinAWorkflow ? (
              <WorkflowTasksSingleWorkflowTableFilterAreaDrawerContent />
            ) : (
              <WorkflowTasksTableFilterAreaDrawerContent
                viewConfigStorageKey={viewConfigStorageKey}
                defaultAssetType={defaultAssetType}
              />
            )}
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
