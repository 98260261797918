import { RecordFlowSummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/RecordFlowSummaryPanel";
import { RecordSummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/RecordSummaryPanel";
import { RecordValueTablePanel } from "#batteries-included-components/Panels/TablePanels/RecordValueTablePanel";
import ReportingGroupPillPanel from "#src/batteries-included-components/Panels/PillPanels/ReportingGroupPillPanel/ReportingGroupPillPanel";
import { useGetOneFlow } from "#src/components/hooks/adapters/useFlows";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import useLocalization from "#src/hooks/useLocalization";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Column, Page, Row } from "@validereinc/common-components";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import { useParams } from "react-router";
import { FlowRecordDetailRoute } from "./index";

const FlowRecordDetailPageContent = ({ flowId }: { flowId: string }) => {
  const { localize } = useLocalization();
  const { record, isLoading } = useContext(RecordContext) ?? {};
  const { data: flow, isLoading: isFlowLoading } = useGetOneFlow(
    { flowId, period: record?.year_month },
    { enabled: !!record?.year_month }
  );

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FlowRecordDetailRoute.title;

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowRecordDetailRoute, {
    detail: { title: flow?.name },
    recordId: { title: pageTitle },
  });

  return (
    <Page
      category={`${localize("Flow")} Record`}
      title={pageTitle}
      breadcrumbs={breadcrumbs}
      isLoading={isLoading || isFlowLoading}
    >
      <Row>
        <Column variant={6}>
          <RecordSummaryPanel />
          <ReportingGroupPillPanel
            id={record?.reporting_group_id}
            isLoading={!record}
          />
        </Column>
        <Column variant={18}>
          {record ? (
            <RecordFlowSummaryPanel
              flowId={flowId}
              period={record?.year_month}
            />
          ) : null}
          <RecordValueTablePanel />
        </Column>
      </Row>
    </Page>
  );
};

export const FlowRecordDetailPage = () => {
  const { flowId } = useParams<{ flowId: string }>();

  return (
    <RecordProvider>
      <FlowRecordDetailPageContent flowId={flowId} />
    </RecordProvider>
  );
};
