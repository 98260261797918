import {
  EquipmentDropdownInput,
  FacilityDropdownInput,
} from "#src/batteries-included-components/Dropdowns";
import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { FilterArea } from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { SavedFilterTag } from "#src/components/hooks/FilterPanel/useSavedFilters";
import {
  EQUIPMENT_STATUS_OPTIONS,
  FACILITY_STATUS_OPTIONS,
} from "#src/constants";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useQueryClient } from "@tanstack/react-query";
import {
  Accordion,
  DateSelectorInput,
  DropdownInput,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  EquipmentStatus,
  FacilityStatus,
  RecordDomain,
  ReportingGroupDomain,
  Resources,
  type GetListResponseType,
  type ReportingGroupType,
} from "@validereinc/domain";
import { sortReportingGroupsByPriority } from "@validereinc/domain-controllers/logic/reporting-groups";
import React from "react";

export const EquipmentRecordsViewFilterArea = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const queryClient = useQueryClient();

  return (
    <FilterArea.Root<{
      reporting_group_id: string;
      date_range: { from: Date; to: Date };
    }>
      storageKey={viewConfigStorageKey}
      defaultValues={async () => {
        const reportingGroupsQueryFromCache = queryClient.getQueryData<
          GetListResponseType<ReportingGroupType>
        >([Resources.REPORTING_GROUP]);
        let defaultReportingGroup = "";

        if (reportingGroupsQueryFromCache?.data) {
          defaultReportingGroup =
            sortReportingGroupsByPriority(reportingGroupsQueryFromCache.data)[0]
              ?.id ?? "";
        } else {
          const reportingGroups = await ReportingGroupDomain.getList({});

          queryClient.setQueryData(
            [Resources.REPORTING_GROUP],
            reportingGroups
          );

          defaultReportingGroup =
            sortReportingGroupsByPriority(reportingGroups.data)[0]?.id ?? "";
        }

        return {
          reporting_group_id: defaultReportingGroup,
          date_range: DEFAULT_DATE_RANGES.lastWholeMonth,
        };
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="View Filters for Equipment Records">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginBottom: 16,
              }}
            >
              <ReportingGroupFilterSwitcher
                name="reporting_group_id"
                onChange={(val) => handleOnChange(val, "reporting_group_id")}
              />
              <DateSelectorInput
                name="date_range"
                variant="month"
                isRange
                isInline
                onChange={(val) => handleOnChange(val, "date_range")}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const EquipmentRecordsTableFilterAreaContent = ({
  selectableProperties,
}: {
  selectableProperties?: string[];
}) => {
  const { localize } = useLocalization();
  const { getTypeName } = useMeasurementTypes();
  const propertySelectorValues = selectableProperties?.map((key) => ({
    key,
    name: getTypeName(key),
  }));
  const equipmentTypesQuery = useListEquipmentTypes({});
  const { customAttributeFilters: facilityCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.FACILITY,
      prefix: "equipment.facility.custom_attributes",
    });
  const { customAttributeFilters: equipmentCustomAttributeFilters } =
    useCustomAttributeFiltersV2({
      assetType: AssetType.EQUIPMENT,
      prefix: "equipment.custom_attributes",
    });

  const equipmentTypes = equipmentTypesQuery.data?.data ?? [];

  return (
    <>
      <DropdownInput
        name="measurement_type"
        label="Measurement Type"
        options={propertySelectorValues}
        placeholder="Select Property..."
        labelKey="name"
        valueKey="key"
        isSearchable
        isOptionalTextShown={false}
      />
      <Accordion defaultActiveKeys={[AssetType.EQUIPMENT]}>
        <Accordion.AccordionPanel
          dataKey={AssetType.EQUIPMENT}
          title={localize(`${AssetType.EQUIPMENT}_plural`)}
        >
          <DropdownInput
            name="equipment.type.id"
            label={`${localize(AssetType.EQUIPMENT)} Type`}
            options={equipmentTypes}
            placeholder="Select Type..."
            labelKey="name"
            valueKey="id"
            isMulti
            isSearchable
            isOptionalTextShown={false}
          />
          <EquipmentDropdownInput
            name="equipment.id"
            placeholder={`Select ${localize(`${AssetType.EQUIPMENT}_plural`)}...`}
            isMulti
            isFluid
            isOptionalTextShown={false}
          />
          <DropdownInput
            name="equipment.status"
            options={EQUIPMENT_STATUS_OPTIONS}
            label={`${localize(AssetType.EQUIPMENT)} Status`}
            placeholder="Select Status..."
            labelKey="label"
            valueKey="value"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          {equipmentCustomAttributeFilters}
        </Accordion.AccordionPanel>
        <Accordion.AccordionPanel
          dataKey={AssetType.FACILITY}
          title={localize(`${AssetType.FACILITY}_plural`)}
        >
          <FacilityDropdownInput
            isMulti
            isFluid
            name="equipment.facility.id"
            placeholder={`Select ${localize(`${AssetType.FACILITY}_plural`)}...`}
            isOptionalTextShown={false}
          />
          <DropdownInput
            name="equipment.facility.status"
            label={`${localize(AssetType.FACILITY)} Status`}
            placeholder="Select Status..."
            options={FACILITY_STATUS_OPTIONS}
            labelKey="label"
            valueKey="value"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
          {facilityCustomAttributeFilters}
        </Accordion.AccordionPanel>
      </Accordion>
    </>
  );
};

export const EquipmentRecordsTableFilterArea = ({
  filterConfigStorageKey,
  selectableProperties,
}: {
  selectableProperties?: string[];
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize } = useLocalization();

  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{
        "equipment.facility.status": [FacilityStatus.ACTIVE],
        "equipment.status": [EquipmentStatus.ACTIVE],
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="Filters for Equipment Records">
        <FilterDrawer.Root>
          <FilterArea.Content>
            {({ handleOnChange }) => (
              <div style={{ marginRight: 8, marginBottom: 0 }}>
                <TextInput
                  name="equipment.name"
                  label="Search"
                  isLabelShown={false}
                  placeholder={`Search ${localize(`${AssetType.EQUIPMENT}_plural`)}...`}
                  type="search"
                  isInline
                  onChange={(val) => handleOnChange(val, "equipment.name")}
                />
              </div>
            )}
          </FilterArea.Content>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <FilterDrawer.SavedFilters
              config={{
                resourceType: Resources.RECORD,
                savedFilterResourceAdapter: RecordDomain.savedFilters,
                tag: SavedFilterTag.EQUIPMENT_RECORDS,
                filterBlacklist: ["date_range.from", "date_range.to"],
              }}
            />
            <FilterDrawer.SavedFiltersAppliedIndicator />
            <EquipmentRecordsTableFilterAreaContent
              selectableProperties={selectableProperties}
            />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
