import { Icon } from "@validereinc/common-components";
import {
  ChoiceType,
  WorkflowTaskStatus,
  WorkflowTaskStatusType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import styles from "./WorkflowTaskUserChoice.module.scss";

const cx = classNames.bind(styles);

export const WorkflowTaskUserChoice = ({
  choices,
  selectedChoiceId,
  setSelectedChoiceId,
  taskStatus,
}: {
  choices: ChoiceType[];
  selectedChoiceId: string;
  setSelectedChoiceId: (choiceId: string) => void;
  taskStatus: WorkflowTaskStatusType;
}) => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const handleKeyDown = (
    evt: React.KeyboardEvent<HTMLElement>,
    choiceId: string
  ) => {
    if (evt.code === "Enter") {
      setSelectedChoiceId(choiceId);
    }
  };

  const handleFocus = (index) => {
    setFocusedIndex(index);
  };

  const handleBlur = () => {
    setFocusedIndex(null);
  };

  const isTaskComplete = taskStatus === WorkflowTaskStatus.COMPLETE;

  return (
    <div
      className={cx(
        "choice-section-container",
        isTaskComplete ? "taskComplete" : ""
      )}
    >
      <p className={cx("choice-section-title")}>Choice Options</p>
      <p className={cx("choice-section-description")}>
        Select an option and click “Complete” to save the selection.
      </p>
      <div className={cx("choice-card-container")}>
        {choices.map((choice: ChoiceType, index) => {
          const isSelected = choice.id === selectedChoiceId;
          const shouldFlash = index === focusedIndex;
          return (
            <div
              className={cx(
                "choice-card",
                "flash-enabled",
                isSelected && "isSelected",
                isTaskComplete && "taskComplete",
                shouldFlash && "flash"
              )}
              key={choice.id}
              onFocus={() => handleFocus(index)}
              onBlur={handleBlur}
              onClick={() => setSelectedChoiceId(choice.id)}
              tabIndex={0}
              onKeyDown={(evt) => {
                handleKeyDown(evt, choice.id);
              }}
            >
              <div>
                <p
                  className={cx(
                    "choice-title",
                    isSelected && "isSelected",
                    isTaskComplete && "taskComplete"
                  )}
                >
                  {choice.name}
                </p>
                <p
                  className={cx(
                    "choice-description",
                    isSelected && "isSelected",
                    isTaskComplete && "taskComplete"
                  )}
                >
                  {choice.description}
                </p>
              </div>
              {isSelected ? (
                <div>
                  <Icon
                    variant="check-circle"
                    size={20}
                    weight="fill"
                    className={cx(
                      "check-circle-icon",
                      isTaskComplete && "taskComplete"
                    )}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
