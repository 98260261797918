import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { KeyValuePanel } from "@validereinc/common-components";
import { type WorkflowType } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React, { ReactNode, useMemo } from "react";
import {
  renderFormSectionName,
  renderFormSubmissionAnswer,
} from "#src/batteries-included-components/Panels/TablePanels/FormSubmissionsTablePanel.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { WorkflowDetailsRoutePath } from ".";

const getAssetTriggerAction = (workflowDetails: WorkflowType) => {
  const { asset_type, action = "" } = workflowDetails.asset_trigger.metadata;
  const displayAction = ["create", "update", "delete"].includes(action)
    ? `${action}d`
    : action;

  return `${startCase(asset_type)} ${startCase(displayAction)}`;
};

export const WorkflowAnsersKeyValuePanel = ({
  workflowDetails,
}: {
  workflowDetails?: WorkflowType;
}) => {
  const { measurementUnits, isLoading } = useMeasurementTypes();

  const populatedData = useMemo(() => {
    const formTrigger: Array<{ title: string; value: ReactNode }> =
      workflowDetails?.form_trigger?.form_submission_id
        ? [
            {
              title: "Trigger",
              value: "Form Submission",
            },
            {
              title: "Triggering Form Submission",
              value: workflowDetails.form_trigger.form_submission ? (
                <RoutingLink
                  to={linkToFormSubmissionDetail(
                    workflowDetails.form_trigger.form_submission_id
                  )}
                >
                  {`${workflowDetails.form_trigger.form_submission.data.form_schema?.name} - ${workflowDetails.form_trigger.form_submission_id.slice(0, 7)}`}
                </RoutingLink>
              ) : (
                "-"
              ),
            },
          ]
        : [];

    let index = 1;
    const { section_id, section_index } =
      workflowDetails?.form_trigger?.metadata || {};
    const formTriggerDetails: Array<{ title: string; value: ReactNode }> =
      workflowDetails?.form_trigger?.metadata?.answers
        ? Object.entries(workflowDetails.form_trigger.metadata.answers).flatMap(
            ([question_id, answer]) => [
              {
                title: `Triggering Form Question ${index}`,
                value: workflowDetails?.form_trigger?.form_submission?.data
                  .form_schema
                  ? `${renderFormSectionName(section_id, section_index, workflowDetails.form_trigger.form_submission?.data.form_schema)}` +
                      " : " +
                      workflowDetails.form_trigger.form_submission?.data
                        .form_schema?.config?.questions[question_id]?.prompt ||
                    "-"
                  : "-",
              },
              {
                title: `Triggering Form Answers ${index++}`,
                value: workflowDetails?.form_trigger?.form_submission?.data
                  .form_schema
                  ? renderFormSubmissionAnswer(answer, question_id, {
                      schema:
                        workflowDetails.form_trigger.form_submission?.data
                          .form_schema,
                      measurementUnits,
                    })
                  : "-",
              },
            ]
          )
        : [];

    const assetTrigger: Array<{ title: string; value: ReactNode }> =
      workflowDetails?.asset_trigger?.metadata?.asset_type
        ? [
            {
              title: "Trigger",
              value: getAssetTriggerAction(workflowDetails),
            },
          ]
        : [];
    const workflowTrigger: Array<{ title: string; value: ReactNode }> =
      workflowDetails?.workflow_trigger?.triggering_workflow_id
        ? [
            {
              title: "Trigger",
              value: "Workflow Completion",
            },
            {
              title: "Triggering Workflow",
              value: workflowDetails?.workflow_trigger
                ?.triggering_workflow_name ? (
                <RoutingLink
                  to={WorkflowDetailsRoutePath.toLinkParts({
                    pathParams: {
                      workflowId:
                        workflowDetails.workflow_trigger.triggering_workflow_id,
                    },
                  })}
                >
                  {`${workflowDetails.workflow_trigger.triggering_workflow_name}`}
                </RoutingLink>
              ) : (
                "-"
              ),
            },
          ]
        : [];
    return formTrigger
      .concat(formTriggerDetails)
      .concat(assetTrigger)
      .concat(workflowTrigger);
  }, [workflowDetails, measurementUnits]);

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ maxRowCount: 10 }}
      displayActionRowCondition="never"
      panelProps={{
        title: "Trigger Details",
        isFluidY: true,
        loaded: Boolean(workflowDetails) || isLoading,
      }}
      data={populatedData}
    />
  );
};
