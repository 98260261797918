import { useFlowProductTypes, useFlowTypes } from "#hooks/useFlowConstants";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useGetOneFlow } from "#src/components/hooks/adapters/useFlows";
import useLocalization from "#src/hooks/useLocalization";
import { KeyValuePanel } from "@validereinc/common-components";
import React from "react";

export const RecordFlowSummaryPanel = ({
  flowId,
  period,
}: {
  flowId: string;
  period: string;
}) => {
  const { localize } = useLocalization();
  const { data: flow, isLoading: isFlowLoading } = useGetOneFlow({
    flowId,
    period,
  });
  const { flowTypes } = useFlowTypes();
  const { flowProductTypes } = useFlowProductTypes();

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ variant: "shaded" }}
      panelProps={{ title: "Details", isFluidY: false, loaded: !isFlowLoading }}
      data={[
        {
          title: `${localize("Flow")}`,
          value: (
            <RoutingLink
              to={FlowDetailRoute.toLink({
                pathParams: { flowId },
              })}
            >
              {flow?.name ?? "-"}
            </RoutingLink>
          ),
        },
        {
          title: "Type",
          value: flowTypes?.find(({ id }) => id === flow?.type)?.name,
        },
        {
          title: "Product Type",
          value: flowProductTypes?.find(({ id }) => id === flow?.product_type)
            ?.name,
        },
      ]}
    />
  );
};
