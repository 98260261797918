import { AssetDropdownInput } from "#src/batteries-included-components/Dropdowns/AssetDropdownInput";
import type { BaseEntityDropdownProps } from "#src/batteries-included-components/Dropdowns/EntityDropdownInput";
import { useCustomAttributeFiltersV2 } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { convertMapToOpts } from "#src/constants";
import useLocalization from "#src/hooks/useLocalization";
import {
  TextInput,
  type ControlledTextInputProps,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

export const AssetTypeSelection = {
  ...AssetType,
  ALL_ASSETS: "all_assets",
} as const;
export type AssetTypeSelectionType =
  (typeof AssetTypeSelection)[keyof typeof AssetTypeSelection];

export const AssetTypeOptions = [
  ...convertMapToOpts(AssetTypeSelection, ([_, value]) => {
    if (value === AssetTypeSelection.ASSET_GROUP) {
      return "Network";
    } else {
      return startCase(value);
    }
  }),
];

export const useAssetDropdownInput = (
  assetType: AssetTypeSelectionType,
  name: string,
  inputProps?: Partial<BaseEntityDropdownProps>
) => {
  const { localize } = useLocalization();
  const commonProps: BaseEntityDropdownProps = {
    name: assetType === AssetTypeSelection.ALL_ASSETS ? "" : name,
    isFluid: true,
    label: localize(assetType),
    placeholder: `Select ${localize(`${assetType}_plural`)}...`,
  };

  if (assetType === AssetTypeSelection.ALL_ASSETS) return <></>;

  return (
    <AssetDropdownInput
      assetType={assetType}
      {...commonProps}
      {...inputProps}
    />
  );
};

export const useAssetSearchInput = (
  assetType: AssetTypeSelectionType,
  name: string,
  inputProps?: Partial<ControlledTextInputProps>
) => {
  const { localize } = useLocalization();
  const commonProps: ControlledTextInputProps = {
    name: assetType === AssetTypeSelection.ALL_ASSETS ? "" : name,
    label: localize(assetType),
    placeholder: "Search Assets...",
    type: "search",
  };

  if (assetType === AssetTypeSelection.ALL_ASSETS) return <></>;

  return (
    <TextInput
      {...commonProps}
      {...(inputProps ?? {})}
    />
  );
};

export const useAssetCustomAttributeFilters = (
  assetType: AssetTypeSelectionType
) => {
  const filters = useCustomAttributeFiltersV2({
    assetType:
      assetType !== AssetTypeSelection.ALL_ASSETS
        ? assetType
        : AssetTypeSelection.FACILITY,
    prefix: `workflow.${assetType}.custom_attributes`,
  });

  if (assetType === AssetTypeSelection.ALL_ASSETS)
    return {
      customAttributes: [],
      customAttributeHeaders: [],
      customAttributeFilters: [],
      isLoading: false,
    };

  return filters;
};
