import { useNavigate } from "#src/Routers/hooks";
import { Button, Panel } from "@validereinc/common-components";
import type { FlowType } from "@validereinc/domain";
import React from "react";
import { useParams } from "react-router";
import { FlowEditRoute } from "../edit";
import { FlowEquipmentTable } from "./FlowEquipmentTable";
import { FlowFacilityTable } from "./FlowFacilityTable";
import { FlowFlowsTable } from "./FlowFlowsTable";

export const FlowDownstreamPanel = ({
  flow,
  isLoading,
}: {
  flow?: FlowType;
  isLoading: boolean;
}) => {
  const { flowId } = useParams<{ flowId: string }>();
  const navigate = useNavigate();

  return (
    <Panel
      title="Destination"
      actionRow={
        <Button
          onClick={() => {
            navigate(FlowEditRoute.toLinkParts({ pathParams: { flowId } }));
          }}
        >
          Edit
        </Button>
      }
    >
      {flow?.downstream_facility ? (
        <FlowFacilityTable
          flow={flow}
          isLoading={isLoading}
          variant="downstream"
        />
      ) : null}

      {flow?.downstream_equipment ? (
        <FlowEquipmentTable
          flow={flow}
          isLoading={isLoading}
          variant="downstream"
        />
      ) : null}

      {flow?.downstream_flows?.length ? (
        <FlowFlowsTable
          flow={flow}
          isLoading={isLoading}
          variant="downstream"
        />
      ) : null}
    </Panel>
  );
};
