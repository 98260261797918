import {
  WorkflowTaskStatus,
  WorkflowTaskSchema,
  WorkflowTaskType,
  WorkflowTaskTypes,
} from "../schemas";
import { WorkflowMock } from "./WorkflowMocks";
import { DomainFaker, DomainMock } from "./utils";

export const WorkflowTaskMock = new DomainMock<
  WorkflowTaskType,
  typeof WorkflowTaskSchema
>(
  WorkflowTaskSchema,
  () => {
    const shouldAssignUserGroup = Math.random() < 0.6; // 60% chance of getting a user group

    return {
      id: DomainFaker.en.string.uuid(),
      name: DomainFaker.en.word.words(),
      type: DomainFaker.en.helpers.arrayElement(
        Object.values(WorkflowTaskTypes)
      ),
      step_id: DomainFaker.en.word.words(1),
      status: DomainFaker.en.helpers.arrayElement(
        Object.values(WorkflowTaskStatus)
      ),
      description: DomainFaker.en.lorem.sentences(2),
      assignee_user: shouldAssignUserGroup
        ? null
        : DomainFaker.en.string.uuid(),
      assignee_group: shouldAssignUserGroup
        ? DomainFaker.en.string.uuid()
        : null,
      restrict_assignee_completion: DomainFaker.en.datatype.boolean(),
      workflow_id: DomainFaker.en.string.uuid(),
      company_id: DomainFaker.en.string.uuid(),
      workflow: WorkflowMock.mock,
      created_at: DomainFaker.en.date.past().toISOString(),
      updated_at: DomainFaker.en.date.past().toISOString(),
      updated_by: DomainFaker.en.string.uuid(),
    };
  },
  true
).fix();

export const WorkflowTaskMockResponse = {
  data: [WorkflowTaskMock.mock],
  page_number: 1,
  page_size: 25,
  total_entries: 1,
  total_pages: 1,
};
