import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import { linkToAssetDetailPage } from "#src/utils/links";
import { WorkflowCategoryDetailsRoutePath } from "#src/routes/workflows/categories/[workflowCategoryId]";
import { WorkflowTemplateDetailsRoutePath } from "#src/routes/workflows/templates/[workflowTemplateId]";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { KeyValuePanel } from "@validereinc/common-components";
import { AssetType, type WorkflowType } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React, { ReactNode, useMemo } from "react";
import {
  renderFormSectionName,
  renderFormSubmissionAnswer,
} from "#src/batteries-included-components/Panels/TablePanels/FormSubmissionsTablePanel.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import useLocalization from "#src/hooks/useLocalization";
import { WorkflowDetailsRoutePath } from ".";

const getAssetTriggerAction = (workflowDetails: WorkflowType) => {
  const { asset_type, action = "" } = workflowDetails.asset_trigger.metadata;
  const displayAction = ["create", "update", "delete"].includes(action)
    ? `${action}d`
    : action;
  return `${startCase(asset_type)} ${startCase(displayAction)}`;
};

export const WorkflowDetailKeyValuePanel = ({
  workflowDetails,
}: {
  workflowDetails?: WorkflowType;
}) => {
  const { localize } = useLocalization();
  const data: Array<{ title: string; value: ReactNode }> = workflowDetails
    ? [
        {
          title: "Category",
          value: workflowDetails?.workflow_template?.workflow_category?.name ? (
            <RoutingLink
              to={WorkflowCategoryDetailsRoutePath.toLinkParts({
                pathParams: {
                  workflowCategoryId:
                    workflowDetails.workflow_template.workflow_category.id,
                },
              })}
            >
              {workflowDetails.workflow_template.workflow_category.name}
            </RoutingLink>
          ) : (
            "-"
          ),
        },
        {
          title: "Description",
          value: workflowDetails?.description ?? "-",
        },
        {
          title: "Template",
          value: workflowDetails?.workflow_template ? (
            <RoutingLink
              to={WorkflowTemplateDetailsRoutePath.toLinkParts({
                pathParams: {
                  workflowTemplateId: workflowDetails.workflow_template_id,
                },
              })}
            >
              {workflowDetails.workflow_template.name}
            </RoutingLink>
          ) : (
            "-"
          ),
        },
        {
          title: `Associated ${localize("facility")}`,
          value:
            workflowDetails.asset &&
            workflowDetails.asset.asset_type !== AssetType.FACILITY &&
            workflowDetails.facility ? (
              <RoutingLink
                key={workflowDetails.facility.id}
                to={linkToFacilityDetail(workflowDetails.facility.id)}
              >
                {workflowDetails.facility.name}
              </RoutingLink>
            ) : (
              "-"
            ),
        },
        {
          title: workflowDetails.asset?.asset_type
            ? localize(workflowDetails.asset.asset_type)
            : "Asset",
          value: workflowDetails.asset ? (
            <RoutingLink
              key={workflowDetails.asset.id}
              to={linkToAssetDetailPage(
                workflowDetails.asset.asset_type,
                workflowDetails.asset.id
              )}
            >
              {workflowDetails.asset.name}
            </RoutingLink>
          ) : (
            "-"
          ),
        },
      ]
    : [];

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ maxRowCount: 10 }}
      displayActionRowCondition="never"
      panelProps={{
        title: "Workflow Details",
        isFluidY: true,
        loaded: Boolean(workflowDetails),
      }}
      data={data}
    />
  );
};
