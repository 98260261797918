import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import Map from "#components/Map/Map";
import Marker from "#components/Map/Marker/Marker";
import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useParams } from "#src/Routers/hooks";
import { useQuery } from "@tanstack/react-query";
import { Link, Panel } from "@validereinc/common-components";
import {
  Resources,
  TemplatedConfigurationRunAdapter,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import styles from "./FacilityDetailOverviewTab.module.scss";
import OverviewPanel from "./OverviewPanel";

const cx = classNames.bind(styles);

const FacilityDetailOverviewTab = ({ facilityDetail, isLoading }) => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });
  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      facilityId,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: facilityId,
      }),
    enabled: isTemplatedConfigsAvailable && !!facilityId,
  });

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const initialViewState = {
    latitude: facilityDetail?.latitude,
    longitude: facilityDetail?.longitude,
  };

  const sharedProps = {
    type: "vertical",
    isLoading,
    allowWrap: true,
    isStatusPanel: true,
  };

  const shouldDisplayMap =
    (facilityDetail?.latitude || facilityDetail?.latitude === 0) &&
    (facilityDetail?.longitude || facilityDetail?.longitude === 0);

  if (!facilityDetail) {
    return null;
  }

  return (
    <div className={cx("overviewAndMapSection")}>
      <Panel isFluidY={false}>
        <SummaryInformationContainer
          variant="vertical"
          status={facilityDetail?.status}
          statusVariant={facilityDetail?.status}
        >
          <SummaryInformation
            {...sharedProps}
            title="Created At"
            value={datetimeFormatter(new Date(facilityDetail?.created_at))}
          />
          <SummaryInformation
            {...sharedProps}
            title="Updated At"
            value={datetimeFormatter(new Date(facilityDetail?.updated_at))}
          />
          {templatedConfigurationRunDependencyGraph ? (
            <SummaryInformation
              {...sharedProps}
              title="Configuration Template"
              value={
                <Link
                  underline="none"
                  onClick={() => setIsTemplatedConfigurationRunDrawerOpen(true)}
                  label={templatedConfigurationRunDependencyGraph.name}
                />
              }
            />
          ) : null}
        </SummaryInformationContainer>
      </Panel>
      <OverviewPanel
        facilityDetail={facilityDetail}
        isLoading={isLoading}
        isDisplayMap={shouldDisplayMap}
      />
      {shouldDisplayMap ? (
        <Map
          initialViewState={initialViewState}
          subtractFromHeightOnResize={16}
        >
          <Marker
            latitude={initialViewState.latitude}
            longitude={initialViewState.longitude}
            iconType="broadcast"
            size="large"
            label={facilityDetail.name}
          />
        </Map>
      ) : null}
      <TemplatedConfigurationRunDrawer
        isOpen={isTemplatedConfigurationRunDrawerOpen}
        onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
        templatedConfigurationRunDependencyGraph={
          templatedConfigurationRunDependencyGraph
        }
        resourceId={facilityId}
      />
    </div>
  );
};

FacilityDetailOverviewTab.propTypes = {
  facilityDetail: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
};

export default FacilityDetailOverviewTab;
