import {
  useGetList,
  useGetOne,
} from "#src/components/hooks/adapters/adapterUtils";
import { Resources, WorkflowTaskAdapter } from "@validereinc/domain";

/** TODO: All workflow tasks should be on the `Resources.WORKFLOW_TASK` key */

export const useGetOneWorkflowTask = useGetOne(
  WorkflowTaskAdapter.getOne,
  Resources.WORKFLOW,
  "task"
);

export const useListWorkflowTasks = useGetList(
  WorkflowTaskAdapter.getList,
  Resources.WORKFLOW,
  "tasks"
);

export const useListWorkflowActions = useGetList(
  WorkflowTaskAdapter.actions.getList,
  Resources.WORKFLOW,
  "actions"
);
