import { RoutePath } from "#utils/route";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";

export const NetworkCalculationResults = NetworkDetailRoute.concat(
  new RoutePath({
    path: "/calculation-result",
    title: "Calculation Results",
    isPresentational: true,
  })
);
