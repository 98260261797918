import { FlowStatusPillVariants } from "#hooks/adapters/useFlows";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useParams } from "#routers/hooks";
import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useQuery } from "@tanstack/react-query";
import { Link, StatusPanel } from "@validereinc/common-components";
import {
  Resources,
  TemplatedConfigurationRunAdapter,
  type FlowType,
  type UserType,
} from "@validereinc/domain";
import { datetimeFormatter, toStartCaseString } from "@validereinc/utilities";
import React, { useState } from "react";

export const FlowStatusPanel = ({
  flow,
  isLoading,
}: {
  flow?: FlowType;
  isLoading: boolean;
}) => {
  const { flowId } = useParams<{ flowId: string }>();
  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });
  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      flowId,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: flowId,
      }),
    enabled: isTemplatedConfigsAvailable && !!flowId,
  });

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const usersQuery = useGetManyUsers(
    flow ? [flow?.created_by, flow?.updated_by] : []
  );
  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const statusPanelData = flow
    ? [
        {
          label: "Updated At",
          value: datetimeFormatter(new Date(flow?.updated_at)),
        },
        {
          label: "Updated By",
          value: userMap[flow?.updated_by]?.name ?? "-",
        },
        {
          label: "Created At",
          value: datetimeFormatter(new Date(flow?.created_at)),
        },
        {
          label: "Created By",
          value: userMap[flow?.created_by]?.name ?? "-",
        },
        {
          label: "Created By",
          value: <div></div>,
        },
        ...(templatedConfigurationRunDependencyGraph
          ? [
              {
                label: "Configuration Run",
                value: (
                  <Link
                    underline="none"
                    onClick={() =>
                      setIsTemplatedConfigurationRunDrawerOpen(true)
                    }
                    label={templatedConfigurationRunDependencyGraph.name}
                  />
                ),
              },
            ]
          : []),
      ]
    : [];

  return (
    <>
      <StatusPanel
        data={statusPanelData}
        isLoading={isLoading}
        statusText={toStartCaseString(flow?.status)}
        statusVariant={
          flow?.status ? FlowStatusPillVariants[flow.status] : "default"
        }
      />
      <IsFeatureAvailable
        featureFlagQuery="core:templated_configurations"
        permissionQuery="templated_configurations:read"
        fallbackChildren={null}
      >
        <TemplatedConfigurationRunDrawer
          isOpen={isTemplatedConfigurationRunDrawerOpen}
          onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
          templatedConfigurationRunDependencyGraph={
            templatedConfigurationRunDependencyGraph
          }
          resourceId={flowId}
        />
      </IsFeatureAvailable>
    </>
  );
};
