import {
  NetworkStatusPillVariants,
  useGetOneNetwork,
} from "#hooks/adapters/useNetworks";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useQuery } from "@tanstack/react-query";
import { Link, StatusPanel, StorageKeys } from "@validereinc/common-components";
import {
  Resources,
  TemplatedConfigurationRunAdapter,
  UserType,
} from "@validereinc/domain";
import {
  datetimeFormatter,
  getYearMonthFromDateRange,
  toStartCaseString,
} from "@validereinc/utilities";
import React, { useState } from "react";

export const NetworkStatusPanel = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });
  const [filters] = useSessionStickyState<{ period: { from?: string } }>(
    {},
    viewConfigStorageKey
  );
  const { data: network, isLoading } = useGetOneNetwork(networkId, {
    period: getYearMonthFromDateRange(filters.period),
  });

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      networkId,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: networkId,
      }),
    enabled: isTemplatedConfigsAvailable && !!networkId,
  });

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const usersQuery = useGetManyUsers([
    network?.created_by,
    network?.updated_by,
  ]);
  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const statusPanelData = network
    ? [
        {
          label: "Updated At",
          value: datetimeFormatter(new Date(network?.updated_at)),
        },
        {
          label: "Updated By",
          value: userMap[network?.updated_by]?.name ?? "-",
        },
        {
          label: "Created At",
          value: datetimeFormatter(new Date(network?.created_at)),
        },
        {
          label: "Created By",
          value: userMap[network?.created_by]?.name ?? "-",
        },
        ...(templatedConfigurationRunDependencyGraph
          ? [
              {
                label: "Configuration Run",
                value: (
                  <Link
                    underline="none"
                    onClick={() =>
                      setIsTemplatedConfigurationRunDrawerOpen(true)
                    }
                    label={templatedConfigurationRunDependencyGraph.name}
                  />
                ),
              },
            ]
          : []),
      ]
    : [];

  return (
    <>
      <StatusPanel
        data={statusPanelData}
        isLoading={isLoading}
        statusText={toStartCaseString(network?.status)}
        statusVariant={NetworkStatusPillVariants[network?.status]}
      />
      <IsFeatureAvailable
        featureFlagQuery="core:templated_configurations"
        permissionQuery="templated_configurations:read"
        fallbackChildren={null}
      >
        <TemplatedConfigurationRunDrawer
          isOpen={isTemplatedConfigurationRunDrawerOpen}
          onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
          templatedConfigurationRunDependencyGraph={
            templatedConfigurationRunDependencyGraph
          }
          resourceId={networkId}
        />
      </IsFeatureAvailable>
    </>
  );
};
