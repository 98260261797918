import { TimezoneDropdownInput } from "#src/batteries-included-components/Dropdowns";
import { getPermissionDisplayLabel } from "#src/contexts/AuthenticatedContext.helpers";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  Banner,
  CheckboxInput,
  DropdownInput,
  Panel,
  TelephoneInput,
  TextInput,
  useFormContext,
} from "@validereinc/common-components";
import {
  RolesAdapter,
  UserCreateSchema,
  UsersAdapter,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useMemo } from "react";
import { useDebounce } from "use-debounce";
import { z } from "zod";
import styles from "./CreateUserDetailsStep.module.scss";

const cx = classNames.bind(styles);

export const UserCreateFormSchema = UserCreateSchema.merge(
  z.object({
    roles: z.array(z.string()).optional(),
  })
);
export type UserCreateFormType = z.infer<typeof UserCreateFormSchema>;

export const CreateUserDetailsStep = ({
  isLoading,
}: {
  isLoading: boolean;
}) => {
  const formKeys = UserCreateFormSchema.keyof().Enum;
  const { watch } = useFormContext<UserCreateFormType>();
  const selectedRoles = watch(formKeys.roles);
  const [enteredEmail] = useDebounce(watch(formKeys.email), 500);

  const duplicateQueryPayload: Parameters<typeof UsersAdapter.getList>[0] = {
    filters: {
      email: enteredEmail,
      isAlreadyFormatted: true,
    },
  };

  const { data: duplicateUsers } = useQuery({
    queryKey: ["users", duplicateQueryPayload],
    queryFn: () => UsersAdapter.getList(duplicateQueryPayload),
    enabled: Boolean(enteredEmail),
  });

  const rolesPermissionsQueries = useQueries({
    queries:
      selectedRoles?.map((roleId) => ({
        queryKey: ["roles", roleId, "permissions"],
        queryFn: () => RolesAdapter.permissions.getMany({ id: roleId }),
        staleTime: 3 * 60 * 1000,
      })) ?? [],
  });
  const rolesToAssociatedPermissionsList = useMemo(() => {
    if (rolesPermissionsQueries.some((q) => q.isLoading)) {
      return [];
    }

    return Array.from(
      rolesPermissionsQueries
        .reduce<Set<string>>((set, query) => {
          if (!query.data?.length) {
            return set;
          }

          query.data.forEach((perm) =>
            set.add(getPermissionDisplayLabel(perm.name))
          );

          return set;
        }, new Set())
        .values()
    );
  }, [rolesPermissionsQueries]);

  return (
    <>
      <Panel title="Details">
        <div className={cx("detailsStepContainer")}>
          <TextInput
            isDisabled={isLoading}
            name={formKeys.name}
            label="Name"
            type="text"
            isRequired
          />

          <TextInput
            isDisabled={isLoading}
            name={formKeys.email}
            label="Email"
            type="text"
            validate={{
              validateEmail: (email: string) =>
                UserCreateFormSchema.shape.email.safeParse(email).success
                  ? true
                  : "Email is invalid",
              uniqueEmail: () => {
                return duplicateUsers?.total_entries
                  ? "An account with this email already exists"
                  : true;
              },
            }}
            isRequired
            description="Cannot be changed again"
          />

          <TimezoneDropdownInput
            name={formKeys.timezone}
            inputId={formKeys.timezone}
          />
        </div>
      </Panel>
      <Panel title="Roles & Permissions">
        <CheckboxInput
          name="quicksight"
          label="User has access to Insights Dashboards"
          isLabelShown={false}
          isFluid
        />
        <DropdownInput
          key="roles"
          name={formKeys.roles}
          onFetchData={async (payload) => {
            let { data } = await RolesAdapter.getList({
              ...payload,
              filters: {
                name: payload.searchTerm,
                status: "active",
              },
            });

            // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
            if (Array.isArray(payload.value)) {
              data = data.filter((d) => payload.value.includes(d.id));
            }

            return data;
          }}
          labelKey="name"
          valueKey="id"
          label="Roles (active only)"
          placeholder="Assign Roles"
          isMulti
          isInline
        />
        {rolesToAssociatedPermissionsList.length ? (
          <Banner
            className={cx("associatedPermissionsBanner")}
            variant="info"
            titleText="The following permissions will be granted to this user based on the assigned roles:"
            descriptionText={rolesToAssociatedPermissionsList.join(", ")}
          />
        ) : (
          <Banner
            className={cx("associatedPermissionsBanner")}
            variant="warning"
            titleText="This user will not have access to anything in the application"
            descriptionText="If this is not intentional, assign a role to get started."
          />
        )}
      </Panel>
    </>
  );
};
