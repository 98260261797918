import { linkToEquipmentDetail } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { DataTable } from "@validereinc/common-components";
import type { FlowType } from "@validereinc/domain";
import React, { useMemo } from "react";

export const FlowEquipmentTable = ({
  variant,
  flow,
  isLoading,
}: {
  variant: "upstream" | "downstream";
  flow?: FlowType;
  isLoading: boolean;
}) => {
  const equipmentDetail = useMemo(
    () => flow?.[`${variant}_equipment`] ?? { id: undefined },
    [flow]
  );

  return (
    <DataTable
      isLoading={isLoading}
      items={[{ ...equipmentDetail, type: "Equipment" }]}
      headers={[
        {
          label: "Name",
          key: "name",
          renderComponent: ({ item }) => (
            <RoutingLink to={linkToEquipmentDetail(equipmentDetail?.id)}>
              {item.name}
            </RoutingLink>
          ),
        },
        {
          label: "Type",
          key: "type",
        },
      ]}
    />
  );
};
