import { useUpdateEstimationMethod } from "#hooks/adapters/useEstimationMethods";
import {
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
  useListFlows,
} from "#hooks/adapters/useFlows";
import {
  useClearNetworksCache,
  useGetOneNetwork,
} from "#hooks/adapters/useNetworks";
import { useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useNavigate, useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { NETWORK_ENTITY_INITIAL_SORT } from "#routes/organization/networks/[networkId]/detail/NetworkDetailsTab/NetworkEntityTablePanel/NetworkEntityTablePanel";
import { FlowsTableFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  Button,
  DataTablePanel,
  HeaderType,
  Switch,
  type StorageKeys,
} from "@validereinc/common-components";
import { AssetType, FlowV2Type } from "@validereinc/domain";
import React from "react";
import { NetworkEditRoute } from "../../edit";

export const NetworkFlowsPanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const { data: assetGroup } = useGetOneNetwork(networkId);
  const navigate = useNavigate();
  const flowIds =
    assetGroup?.assets
      ?.filter(({ asset_type }) => asset_type === AssetType.FLOW)
      .map(({ id }) => id) ?? [];

  const flowTypesQuery = useListFlowTypes();
  const flowProductTypesQuery = useListFlowProductCategories();
  const flowProductCategoriesQuery = useListFlowProductTypes();
  const [tableFilters] = useSessionStickyState({}, filterConfigStorageKey);
  const filters = { ...tableFilters, id: flowIds };
  const [tableState, updateTableState] = useTableSortingAndPagination(
    NETWORK_ENTITY_INITIAL_SORT,
    filters
  );

  const {
    data,
    isLoading: isFlowsLoading,
    isFetching,
  } = useListFlows(
    {
      page: tableState.page,
      pageSize: tableState.itemsPerPage,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filters,
    },
    { enabled: !!flowIds?.length }
  );

  const { invalidate } = useClearNetworksCache();
  const updateEstimationMethod = useUpdateEstimationMethod({
    onSuccess: () => {
      invalidate();
    },
  });

  const onToggleAdjustable = (flowId: string, isChecked: boolean) => {
    const previousProrationValue =
      assetGroup?.default_estimation_method?.default_asset_values?.[flowId]
        ?.is_prorated;

    updateEstimationMethod.mutate({
      id: assetGroup?.default_estimation_method?.id,
      entityType: AssetType.ASSET_GROUP,
      data: {
        default_asset_values: {
          [flowId]: {
            is_prorated: {
              // If we don't spread the old value, we lose the unit/type/quantity config
              ...previousProrationValue,
              value: isChecked,
            },
          },
        },
      },
    });
  };

  const headers: Array<HeaderType<FlowV2Type>> = [
    ...useFlowHeaders(),
    {
      key: "adjustable",
      label: "Default Adjustable",
      renderComponent: ({ item }) => {
        return (
          <Switch
            id={item.id}
            isDisabled={updateEstimationMethod.isLoading || isFetching}
            value={
              !!assetGroup?.default_estimation_method?.default_asset_values[
                item.id
              ]?.is_prorated?.value
            }
            onChange={(isChecked) => onToggleAdjustable(item.id, isChecked)}
          />
        );
      },
    },
  ];

  const isLoading =
    (!!flowIds?.length && isFlowsLoading) ||
    flowTypesQuery.isLoading ||
    flowProductTypesQuery.isLoading ||
    flowProductCategoriesQuery.isLoading;

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Network Flows",
        actionRow: (
          <>
            <Button
              onClick={() => {
                navigate({
                  pathname: NetworkEditRoute.toLink({
                    pathParams: { networkId },
                  }),
                });
              }}
            >
              Edit
            </Button>
          </>
        ),
      }}
      dataTableProps={{
        isLoading,
        headers,
        items: data?.data ?? [],
        sorting: NETWORK_ENTITY_INITIAL_SORT,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: data?.total_entries ?? 0,
        },
      }}
      filterComponent={
        <FlowsTableFilterArea
          filterConfigStorageKey={filterConfigStorageKey}
          hasSubAssetFilters
        />
      }
    />
  );
};
