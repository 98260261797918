import {
  AssetTypeType,
  EquipmentType,
  FacilityType,
  ReportType,
} from "../schemas";
import {
  FilterObjectType,
  GetListRequestType,
  GetListResponseType,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";
import { SavedFiltersDomain } from "./composable";

export type GetListCalculatorResultsFiltersType = FilterObjectType<{
  entity_type?: AssetTypeType;
  entity_id?: string;
  year_month?: string;
  reporting_group?: string;
  measurement_type?: string;
  "equipment.type.id"?: string;
  "equipment.type.name"?: string;
  "equipment.id"?: string;
  "equipment.name"?: string;
  "equipment.status"?: string;
  "equipment.facility.status"?: string;
  "equipment.facility.id"?: string;
  "equipment.facility.name"?: string;
  "estimation_method.analytics_calculator_id"?: string;
}>;

export type CalculatorResultType = {
  measurement: CalculatorResultMeasurementType;
  // the following fields are present depending on the query made
  year_month?: string;
  entity_id?: string;
  entity_type?: AssetTypeType;
  estimation_method_id?: string;
  estimation_method_name?: string;
  measurement_type?: string;
  equipment?: EquipmentType;
  "equipment.id"?: string;
  "equipment.name"?: string;
  "equipment.method"?: string;
  "equipment.status"?: string;
  "equipment.type.id"?: string;
  "equipment.type.name"?: string;
  facility?: FacilityType;
  "equipment.facility.id"?: string;
  "equipment.facility.name"?: string;
  "equipment.facility.status"?: string;
  "estimation_method.analytics_calculator_id"?: string;
  /** the ID of the reporting group */
  reporting_group: string;
};

export type CalculatorResultMeasurementType = Record<string, number>;

export const CalculatorResultsDomain = {
  /**
   * Search and aggregate calculator results
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/calculator_results/calculator_results
   */
  getList: ({
    filters,
    page,
    pageSize,
    sortBy,
    sortDirection,
    groupBy,
    isExcelFile,
    ...restProps
  }: GetListRequestType<GetListCalculatorResultsFiltersType> & {
    isExcelFile?: boolean;
  }): Promise<GetListResponseType<CalculatorResultType>> => {
    const formattedFilters = getFilterObject(filters);

    return fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST<GetListResponseType<CalculatorResultType>>({
          endpoint: "/calculator_results",
          body: {
            ...restProps,
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: formattedFilters,
            group_by: groupBy,
          },
          ...(isExcelFile
            ? {
                headers: {
                  Accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
                responseType: "arraybuffer",
              }
            : {}),
        }),
      page,
      pageSize
    );
  },

  /**
   * Export search and aggregation of calculator results
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/calculator_results/export_calculator_results
   */
  export: async ({
    filters,
    sortBy,
    sortDirection,
    groupBy,
  }: GetListRequestType<GetListCalculatorResultsFiltersType>) => {
    const formattedFilters = getFilterObject(filters);

    return await restAPI.nodeAPI.POST<ReportType>({
      endpoint: "/calculator_results/export",
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: formattedFilters,
        group_by: groupBy,
      },
    });
  },

  /**
   * Calculator Results Saved Filter APIs
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/calculator_results/get_calculator_results_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/calculator_results/post_calculator_results_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/calculator_results/delete_calculator_results_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/calculator_results/put_calculator_results_filter
   */
  savedFilters: SavedFiltersDomain<GetListCalculatorResultsFiltersType>({
    endpoint: "/calculator_results/filters",
  }),
};
