import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { EmissionsRecordDetail } from "#src/batteries-included-components/Layouts/Emissions/Record/Detail";
import { ORGANIZATION_BREADCRUMB } from "#src/routes/organization";
import { EQUIPMENT_LIST_BREADCRUMB } from "#src/routes/organization/equipment";
import { EQUIPMENT_DETAIL_BREADCRUMB } from "#src/routes/organization/equipment/[equipmentId]";
import { EQUIPMENT_EMISSION_RECORD_LIST_BREADCRUMB } from "#src/routes/organization/equipment/[equipmentId]/emissions";
import {
  EQUIPMENT_EMISSION_RECORD_DETAIL_BREADCRUMB,
  EQUIPMENT_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE,
} from "#src/routes/organization/equipment/[equipmentId]/emissions/[emissionPeriod]/detail";
import { useQuery } from "@tanstack/react-query";
import { Page } from "@validereinc/common-components";
import { EquipmentDomainV2, Resources } from "@validereinc/domain";
import {
  DateFormats,
  monthFormatter,
  yearMonthFormatter,
} from "@validereinc/utilities";
import parse from "date-fns/parse";
import React, { useState } from "react";
import { useParams } from "react-router";

const EmissionsRecordDetailPage = () => {
  // state
  const { equipmentId, emissionPeriod } = useParams<{
    equipmentId: string;
    emissionPeriod: string;
  }>();
  const [resultPeriod] = useState(() =>
    parse(emissionPeriod, DateFormats.YEAR_MONTH, new Date())
  );
  const { data: equipmentDetail, isLoading: isEquipmentLoading } = useQuery({
    queryKey: [Resources.EQUIPMENT, equipmentId, emissionPeriod],
    queryFn: () =>
      EquipmentDomainV2.getOne({
        id: equipmentId,
        meta: { period: yearMonthFormatter(resultPeriod) },
      }),
    select: (resp) => resp.data,
    enabled: !!equipmentId && !!resultPeriod,
  });

  // effects & callbacks

  // computed
  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      EQUIPMENT_LIST_BREADCRUMB,
      EQUIPMENT_DETAIL_BREADCRUMB,
      EQUIPMENT_EMISSION_RECORD_LIST_BREADCRUMB,
      EQUIPMENT_EMISSION_RECORD_DETAIL_BREADCRUMB,
    ],
    {
      2: equipmentDetail?.name,
      4: monthFormatter(resultPeriod),
    }
  );

  const pageTitle = resultPeriod
    ? `${
        equipmentDetail?.name ? `${equipmentDetail.name} ` : ""
      }${monthFormatter(resultPeriod)} Emissions Record`
    : EQUIPMENT_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE;

  const emissionRecordsDetailFilters = {
    entity_id: equipmentId,
  };

  return (
    <Page
      isLoading={isEquipmentLoading}
      breadcrumbs={breadcrumbs}
      category={EQUIPMENT_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE}
      title={pageTitle}
    >
      <EmissionsRecordDetail
        filters={emissionRecordsDetailFilters}
        timePeriod={emissionPeriod}
      >
        <EmissionsRecordDetail.EstimationMethodPanel
          filters={emissionRecordsDetailFilters}
          timePeriod={emissionPeriod}
        />
      </EmissionsRecordDetail>
    </Page>
  );
};

export default EmissionsRecordDetailPage;
