import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";

export const LegacyCompanyClaimSchema = z
  .object({
    name: z.string(),
    int_id: z.number(),
  })
  .merge(DomainModelSchema);

export const CompanySchema = z
  .object({
    name: z.string(),
    localization: z.record(z.string()),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a Company model in the Node API");

export type LegacyCompanyClaimType = z.infer<typeof LegacyCompanyClaimSchema>;
export type CompanyType = z.infer<typeof CompanySchema>;
