import React from "react";
import { BasicInfoFormStep } from "./BasicInfoFormStep";
import { FlowConnectionStep } from "./FlowConnectionsStep";

export const FlowFormPanel = ({
  isConnectionEditOnly,
  flowId,
}: {
  isConnectionEditOnly?: boolean;
  flowId: string;
}) => {
  if (isConnectionEditOnly) {
    return (
      <FlowConnectionStep
        flowId={flowId}
        stepNumberOverride={1}
      />
    );
  }

  return (
    <>
      <BasicInfoFormStep flowId={flowId} />
      <FlowConnectionStep flowId={flowId} />
    </>
  );
};
