import { HomeRoute } from "#src/routes/home";
import "airbnb-js-shims";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import ErrorPage from "../components/Authentication/ErrorPage";
import Login from "../components/Authentication/Login";
import LoginCallback from "../components/Authentication/LoginCallback";
import Logout from "../components/Authentication/Logout";
import { Routes } from "./PageConfigs";
import PrivateRoute from "./PrivateRoute";
import { linkToNotFound } from "#src/routes/not-found";
import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

const RouteList = () => {
  const { pathname, ...locationData } = useLocation();

  return (
    <>
      <Switch>
        <SentryRoute
          path="/app/callback"
          component={LoginCallback}
        />
        <SentryRoute
          path="/app/logout"
          component={Logout}
        />

        {/* Backwards compatability with previous bookmarks that were made
            when "operations-hub" was included in basename */}
        <SentryRoute
          path="/operations-hub/:url"
          render={() => (
            <Redirect
              to={{
                ...locationData,
                pathname: pathname
                  .replace(/^\/operations-hub\/(.*)/, /$1/)
                  .replace(/\/$/, ""), // remove trailing slash
              }}
            />
          )}
        />

        <SentryRoute
          path="/app/signin"
          render={() => <Redirect to="/app/login" />}
        />
        <SentryRoute
          path="/app/login"
          component={Login}
        />

        <SentryRoute
          path="/app/auth0Error"
          component={ErrorPage}
        />

        <SentryRoute
          exact
          path="/app/inline-instruments"
          render={() => <Redirect to="/app/instrument/list" />}
        />
        <SentryRoute
          exact
          path="/app/instrument"
          render={() => <Redirect to="/app/instrument/list" />}
        />

        {Routes.map((route) => {
          // TODO: fatal flow from the legacy code here - all direct children of
          // a <Switch /> component must be a <Route /> or a <Redirect />. This
          // is entirely incorrect. The <PrivateRoute />, PageConfigs, and the
          // <LayoutContent /> need significant refactoring.
          return (
            <PrivateRoute
              key={route.path}
              path={route.path}
              exact={route.exact}
            />
          );
        })}

        <SentryRoute
          path="/"
          exact
          render={() => <Redirect to={HomeRoute.path} />}
        />

        <SentryRoute render={() => <Redirect to={linkToNotFound()} />} />
      </Switch>
    </>
  );
};

export default connect()(RouteList);
