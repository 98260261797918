import { useListFlowTypes } from "#hooks/adapters/useFlows";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { DataTable } from "@validereinc/common-components";
import type { FlowType } from "@validereinc/domain";
import React, { useMemo } from "react";
import { FlowDetailRoute } from "../.";

export const FlowFlowsTable = ({
  variant,
  flow,
  isLoading,
}: {
  variant: "upstream" | "downstream";
  flow?: FlowType;
  isLoading: boolean;
}) => {
  const flowData = useMemo(() => flow?.[`${variant}_flows`], [flow]);
  const { data: flowTypes } = useListFlowTypes();

  return (
    <DataTable
      isLoading={isLoading}
      items={flowData ?? []}
      headers={[
        {
          label: "Name",
          key: "name",
          renderComponent: ({ item: { id, name } }: { item: FlowType }) => (
            <RoutingLink
              to={FlowDetailRoute.toLink({ pathParams: { flowId: id } })}
            >
              {name}
            </RoutingLink>
          ),
        },
        {
          label: "Type",
          key: "type",
          renderComponent: ({ item: { type } }: { item: FlowType }) =>
            flowTypes?.find(({ id }) => id === type)?.name ?? "-",
        },
      ]}
    />
  );
};
