import type { ReactNode } from "react";
import { getSafeFileMimeType, getSafeFileName } from "./logic/files";
import {
  areConditionsSatisfied,
  ConditionFunctions,
  getErrorCount,
  getParentQuestionName,
  getSmartDefaultValues,
  getValuePathFromQuestionPath,
  useGenerateFieldsToWatch,
  useGenerateLookupQueries,
  useProcessAttributeLookupQueries,
  useProcessCalculatedFields,
  type QuestionConditionType,
} from "./logic/forms";
import {
  getDisplayedUnits,
  NON_DISPLAY_UNITS,
  NON_PRECISION_UNITS,
} from "./logic/measurements";
import { getResourceIdForResource } from "./logic/templated-configurations";
import {
  FormSubmissionFormController,
  FormSubmissionsSectionRepeater,
  type FormSubmissionFormControllerType,
  type QuestionRendererPropType,
  type QuestionRenderFunctionType,
  type SectionRendererPropType,
  type SectionRenderFunctionType,
} from "./view/forms";
import { sortReportingGroupsByPriority } from "./logic/reporting-groups";

type ResourceControllersType = {
  view: Record<string, ReactNode>;
  logic: Record<string, any>;
};

const Forms = {
  view: {
    FormSubmissionFormController,
    FormSubmissionsSectionRepeater,
  },
  logic: {
    areConditionsSatisfied,
    ConditionFunctions,
    getParentQuestionName,
    getSmartDefaultValues,
    getErrorCount,
    getValuePathFromQuestionPath,
    useGenerateFieldsToWatch,
    useGenerateLookupQueries,
    useProcessAttributeLookupQueries,
    useProcessCalculatedFields,
  },
} satisfies ResourceControllersType;

const Files = {
  view: {},
  logic: {
    getSafeFileName,
    getSafeFileMimeType,
  },
} satisfies ResourceControllersType;

const Measurements = {
  view: {},
  logic: {
    NON_DISPLAY_UNITS,
    NON_PRECISION_UNITS,
    getDisplayedUnits,
  },
} satisfies ResourceControllersType;

const TemplatedConfigurations = {
  view: {},
  logic: {
    getResourceIdForResource,
  },
} satisfies ResourceControllersType;

const ReportingGroups = {
  view: {},
  logic: {
    sortReportingGroupsByPriority,
  },
} satisfies ResourceControllersType;

export { Files, Forms, Measurements, TemplatedConfigurations, ReportingGroups };
export type {
  FormSubmissionFormControllerType,
  QuestionConditionType,
  QuestionRendererPropType,
  QuestionRenderFunctionType,
  SectionRendererPropType,
  SectionRenderFunctionType,
};
