import {
  linkToEquipmentDetail,
  linkToFormSubmissionDetail,
} from "#src/Routers/links";
import { NetworkDetailRoute } from "#src/routes/organization/networks/[networkId]/detail";
import { linkToEstimationMethodDetail } from "#src/routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/detail";
import { FlowEstimationMethodDetailRoute } from "#src/routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]";
import { FlowDetailRoute } from "#src/routes/organization/flows/[flowId]/detail";
import {
  TemplatedConfigurationDependencyGraphResourceNodeType,
  TemplatedConfigurationResourceTypes,
  TemplatedConfigurationResourceTypeType,
} from "@validereinc/domain";
import useLocalization from "#src/hooks/useLocalization";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

export const getResourceTypeDisplayName = (type: string, plural = false) => {
  const { localize } = useLocalization();
  switch (type) {
    case TemplatedConfigurationResourceTypes.equipment:
      if (plural) {
        return localize("equipment_plural");
      }
      return localize("equipment");
    case TemplatedConfigurationResourceTypes.flow:
      if (plural) {
        return localize("flow_plural");
      }
      return localize("flow");
    case TemplatedConfigurationResourceTypes.asset_group:
      if (plural) {
        return localize("asset_group_plural");
      }
      return localize("asset_group");
    case TemplatedConfigurationResourceTypes.asset_group_asset:
      if (plural) {
        return localize("asset_plural");
      }
      return localize("asset");
    case TemplatedConfigurationResourceTypes.estimation_method:
      return "Estimation Methods";
    case TemplatedConfigurationResourceTypes.form_submission:
      return "Form Submissions";
    case TemplatedConfigurationResourceTypes.default_record_value_configuration:
      return "Default Record Value Configurations";
    case TemplatedConfigurationResourceTypes.reporting_group:
      return "Reporting Groups";
    case TemplatedConfigurationResourceTypes.reporting_group_estimation_method:
      return "Reporting Group Estimation Methods";
    case TemplatedConfigurationResourceTypes.default_calculator_input_record:
      return "Input Sourcing";
    default:
      return "";
  }
};

export const canLinkToResource = (
  resource: TemplatedConfigurationDependencyGraphResourceNodeType,
  currentResourceId: string
) => {
  if (resource.id === currentResourceId) {
    return false;
  }

  const linkableResourceTypes: TemplatedConfigurationResourceTypeType[] = [
    TemplatedConfigurationResourceTypes.equipment,
    TemplatedConfigurationResourceTypes.flow,
    TemplatedConfigurationResourceTypes.asset_group,
    TemplatedConfigurationResourceTypes.estimation_method,
    TemplatedConfigurationResourceTypes.form_submission,
  ];

  return linkableResourceTypes.includes(resource.type);
};

export const navigateToResource = (
  resource_type: string,
  resource_id: string,
  linked_resource_type = "",
  linked_resource_id = ""
) => {
  switch (resource_type) {
    case TemplatedConfigurationResourceTypes.flow:
      return FlowDetailRoute.toLink({ pathParams: { flowId: resource_id } });
    case TemplatedConfigurationResourceTypes.form_submission:
      return linkToFormSubmissionDetail(resource_id);
    case TemplatedConfigurationResourceTypes.equipment:
      return linkToEquipmentDetail(resource_id);
    case TemplatedConfigurationResourceTypes.asset_group:
      return NetworkDetailRoute.toLink({
        pathParams: { networkId: resource_id },
      });
    case TemplatedConfigurationResourceTypes.estimation_method:
      return linked_resource_type === "equipment"
        ? linkToEstimationMethodDetail(resource_id, linked_resource_id)
        : linked_resource_type === "flow"
          ? FlowEstimationMethodDetailRoute.toLinkParts({
              pathParams: {
                flowId: linked_resource_id ?? "",
                estimationMethodId: resource_id,
              },
            })
          : "";
    default:
      return "";
  }
};

export const getResourceDisplayName = (
  resource: TemplatedConfigurationDependencyGraphResourceNodeType
) => {
  if (
    resource.type ===
    TemplatedConfigurationResourceTypes.default_record_value_configuration
  ) {
    return startCase(toLower(resource.name));
  }

  if (
    resource.type ===
    TemplatedConfigurationResourceTypes.default_calculator_input_record
  ) {
    return startCase(toLower(resource.name));
  }

  return resource.name;
};
