import { useGetOneEstimationMethodConfiguration } from "#hooks/adapters/useEstimationMethods";
import { useListFlows } from "#hooks/adapters/useFlows";
import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import {
  useNetworkCalculationFlowHeaders,
  useNetworkCalculationSourceHeaders,
} from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationTab.helpers";
import type { CalculationsViewFilterAreaType } from "#src/batteries-included-components/FilterAreas/CalculationsFilterAreas";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  DataTablePanel,
  HeaderType,
  SortingType,
  StorageKeys,
} from "@validereinc/common-components";
import { AssetType, FlowV2Type, SortDirection } from "@validereinc/domain";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import React from "react";

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const NetworkCalculationInletPanel = ({
  viewConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const { data: assetGroup } = useGetOneNetwork(networkId);
  const [viewFilters] = useSessionStickyState<CalculationsViewFilterAreaType>(
    {},
    viewConfigStorageKey
  );

  const filters = {
    id: assetGroup?.network_inlet_ids ?? [],
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { data: flows, isLoading: isFlowsLoading } = useListFlows(
    {
      page: tableState.page,
      pageSize: tableState.itemsPerPage,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filters,
    },
    { enabled: !!filters.id.length }
  );

  const isLoading = isFlowsLoading && !!filters.id.length;

  const { data: configuration } = useGetOneEstimationMethodConfiguration({
    id: assetGroup?.default_estimation_method_id,
    assetType: AssetType.ASSET_GROUP,
    yearMonth: getYearMonthFromDateRange(
      viewFilters.date_range ?? DEFAULT_DATE_RANGES.currentWholeMonth
    ),
  });

  const items = flows?.data ?? [];

  const headers: Array<HeaderType<FlowV2Type>> = [
    ...useNetworkCalculationFlowHeaders(),
    ...useNetworkCalculationSourceHeaders(configuration),
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{ title: "Inlet" }}
      dataTableProps={{
        headers,
        items,
        isLoading,
        sorting,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: flows?.total_entries ?? 0,
        },
      }}
    />
  );
};
