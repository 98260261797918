import { FlowFormPanel } from "#batteries-included-components/Panels/FormPanels/FlowFormPanel/FlowFormPanel";
import { useGetOneFlow, useUpdateFlow } from "#hooks/adapters/useFlows";
import { useNavigate, useParams } from "#routers/hooks";
import { FlowEditRoute } from "#routes/organization/flows/[flowId]/detail/edit/index";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Page } from "@validereinc/common-components";
import { EditFlowType } from "@validereinc/domain";
import React from "react";
import { FlowDetailRoute } from "../index";

const EditFlowPageContent = ({ flowId }: { flowId: string }) => {
  const { stepper, footer } = useMultiStepFormContext();
  const { data: flow, isLoading: isFlowLoading } = useGetOneFlow(
    {
      flowId,
    },
    {
      enabled: !!flowId,
    }
  );

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowEditRoute, {
    detail: { title: flow?.name },
  });

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={FlowEditRoute.title}
      actionRow={stepper}
      footer={footer}
      isLoading={isFlowLoading}
    >
      <FlowFormPanel
        flowId={flowId}
        isConnectionEditOnly
      />
    </Page>
  );
};

export const EditFlowPage = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const navigate = useNavigate();
  const backToFlow = () =>
    navigate(FlowDetailRoute.toLinkParts({ pathParams: { flowId } }));

  const updateFlow = useUpdateFlow({
    onSuccess: backToFlow,
  });

  const onSubmit = (values: Array<Partial<EditFlowType>>) => {
    updateFlow.mutate({
      flowId,
      ...values.reduce((total, current) => ({ ...total, ...current }), {}),
    });
  };

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Connections",
        },
      ]}
      onCancel={backToFlow}
      onSubmit={onSubmit}
      onSubmitMutationState={{
        isSubmitting: updateFlow.isLoading,
      }}
    >
      <EditFlowPageContent flowId={flowId} />
    </MultiStepFormProvider>
  );
};
