import { PermissionType } from "../schemas";
import {
  fetchAndCollate,
  GetListRequestType,
  GetListResponseType,
  ResourceServiceType,
} from "../util";
import { restAPI } from "./api";

export const PermissionsAdapter: ResourceServiceType<PermissionType> = {
  /**
   * Get permissions list
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/permissions/list_permissions
   * @returns the list of permissions
   */
  getList: ({
    page,
    pageSize,
    sortBy,
    sortDirection,
    filters,
  }: GetListRequestType<{ name: string }>) =>
    fetchAndCollate<PermissionType>(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.GET<GetListResponseType<PermissionType>>({
          endpoint: "/permissions",
          query: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            ...(filters?.name ? { name: filters.name } : {}),
          },
        }),
      page,
      pageSize
    ),
  getOne: () =>
    Promise.reject({
      success: false,
      message: "Method not implemented",
    }),
  getMany: () =>
    Promise.reject({
      success: false,
      message: "Method not implemented",
    }),
  createOne: () =>
    Promise.reject({
      success: false,
      message: "Method not implemented",
    }),
  createMany: () =>
    Promise.reject({
      success: false,
      message: "Method not implemented",
    }),
  updateOne: () =>
    Promise.reject({
      success: false,
      message: "Method not implemented",
    }),
  updateMany: () =>
    Promise.reject({
      success: false,
      message: "Method not implemented",
    }),
  deleteOne: () =>
    Promise.reject({
      success: false,
      message: "Method not implemented",
    }),
  deleteMany: () =>
    Promise.reject({
      success: false,
      message: "Method not implemented",
    }),
};
