import { NetworkCalculationInletPanel } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationInletPanel";
import { NetworkCalculationSummaryPanel } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationSummaryPanel";
import { CalculationsViewFilterArea } from "#src/batteries-included-components/FilterAreas/CalculationsFilterAreas";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { NetworkCalculationConsumersPanel } from "#src/routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationConsumersPanel";
import React from "react";

export const NetworkCalculationTabV2 = () => {
  const storageKeys = useStorageKey("network-calculation");
  // separate table config key for one of the panels, since table config key
  // can't be shared across two tables
  const { tableConfigStorageKey } = useStorageKey(
    "network-calculation-consumers"
  );

  return (
    <>
      <CalculationsViewFilterArea
        viewConfigStorageKey={storageKeys.viewConfigStorageKey}
        isPeriodDateRange={false}
      />
      <NetworkCalculationSummaryPanel
        viewConfigStorageKey={storageKeys.viewConfigStorageKey}
      />
      <NetworkCalculationInletPanel {...storageKeys} />
      <NetworkCalculationConsumersPanel
        viewConfigStorageKey={storageKeys.viewConfigStorageKey}
        tableConfigStorageKey={tableConfigStorageKey}
      />
    </>
  );
};
