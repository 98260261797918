import {
  CreateEstimationMethodForm,
  useCreateEstimationMethodForm,
} from "#batteries-included-components/Layouts/EstimationMethod/Create";
import { useNavigate, useParams } from "#routers/hooks";
import {
  FlowDetailParamsType,
  FlowDetailRoute,
} from "#routes/organization/flows/[flowId]/detail";
import { FlowCreateEstimationMethodRoute } from "#routes/organization/flows/[flowId]/detail/create-estimation-method/index";
import { useGetOneFlow } from "#src/components/hooks/adapters/useFlows";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Page } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React from "react";

export const CreateFlowEstimationMethodPage = () => {
  const navigate = useNavigate();
  const { flowId } = useParams<FlowDetailParamsType>();
  const { data: flow } = useGetOneFlow({ flowId });

  const [breadcrumbs] = useBreadcrumbsFromRoute(
    FlowCreateEstimationMethodRoute,
    { detail: { title: flow?.name } }
  );

  const { footer, formProps } = useCreateEstimationMethodForm({
    entityId: flow?.id,
    entityType: AssetType.FLOW,
    mutationType: "update",
    onSubmit: () => {
      navigate(
        FlowDetailRoute.toLinkParts({
          pathParams: { flowId },
          queryParams: { tab: "estimation_methods" },
        })
      );
    },
    onCancel: () => {
      navigate(
        FlowDetailRoute.toLinkParts({
          pathParams: { flowId },
          queryParams: { tab: "estimation_methods" },
        })
      );
    },
  });

  return (
    <Page
      title={FlowCreateEstimationMethodRoute.title}
      breadcrumbs={breadcrumbs}
      footer={footer}
    >
      <CreateEstimationMethodForm formProps={formProps} />
    </Page>
  );
};
