import { useQuery } from "@tanstack/react-query";
import {
  DropdownInput,
  FormInputProps,
  FormInputWrapperProps,
} from "@validereinc/common-components";
import { UserGroupsAdapter } from "@validereinc/domain";
import React from "react";

export const UserGroupsDropdownInput = ({
  excludeUserGroupIds,
  name,
  isMulti,
  value,
  ...restProps
}: FormInputWrapperProps &
  FormInputProps & {
    excludeUserGroupIds?: string[];
  }) => {
  const singleQueryPayload: Parameters<typeof UserGroupsAdapter.getList>[0] = {
    filters: {
      id: value ?? isMulti ? [] : "",
    },
  };

  const defaultValueQuery = useQuery({
    queryKey: ["users", "groups", singleQueryPayload],
    queryFn: async () => {
      const { data } = await UserGroupsAdapter.getList(singleQueryPayload);

      return data?.[0];
    },
    enabled: Boolean(value),
  });

  const allUserGroupsQuery = useQuery({
    queryKey: ["users", "groups"],
    queryFn: async () => {
      return UserGroupsAdapter.getList({});
    },
  });

  return (
    <DropdownInput
      name={name ?? "userGroup"}
      placeholder="Select a user group"
      isMulti={isMulti}
      {...restProps}
      options={allUserGroupsQuery.data?.data ?? []}
      defaultValue={defaultValueQuery.data ?? ""}
      isLoading={allUserGroupsQuery.isLoading}
      labelKey="name"
      valueKey="id"
      onFetchData={async (payload) => {
        let { data } = await UserGroupsAdapter.getList({
          ...payload,
          filters: {
            name: payload.searchTerm,
          },
        });
        // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
        if (Array.isArray(payload.value)) {
          data = data.filter((d) => payload.value.includes(d.id));
        }

        if (excludeUserGroupIds?.length) {
          data = data.filter((u) => !excludeUserGroupIds.includes(u.id));
        }

        return data;
      }}
    />
  );
};
