import { WorkflowTaskCompleteEvent } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskCompleteEvent";
import { getFormSchemaId } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskDetailDrawer.helpers";
import { WorkflowTaskFormSubmission } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskFormSubmission";
import { useGetOneFormSchema } from "#src/components/hooks/adapters/useFormSchema";
import { useGetOneWorkflow } from "#src/components/hooks/adapters/useWorkflows";
import { useGetOneWorkflowTask } from "#src/components/hooks/adapters/useWorkflowTasks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  ButtonWithPopover,
  Drawer,
  Tooltip,
  useAlert,
} from "@validereinc/common-components";
import type { WorkflowTaskType, WorkflowType } from "@validereinc/domain";
import {
  Resources,
  WorkflowTaskAdapter,
  WorkflowTaskStatus,
  WorkflowTaskTypes,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./WorkflowTaskDetailDrawer.module.scss";
import { WorkflowTaskDetailKeyValueList } from "./WorkflowTaskDetailKeyValueList";
import { WorkflowTaskDetailStatusKeyValueList } from "./WorkflowTaskDetailStatusKeyValueList";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useListUserGroups } from "#src/components/hooks/adapters/useUserGroups";
import { WorkflowTaskUserChoice } from "./WorkflowTaskUserChoice";

const cx = classNames.bind(styles);

type WorkflowDetailsDrawerProps = {
  setSelectedTask: React.Dispatch<
    React.SetStateAction<WorkflowTaskType | null>
  >;
  selectedTask: WorkflowTaskType | null;
  onReassignTask?: (task: WorkflowTaskType) => void;
  openTaskStepIds: string[];
};

export const WorkflowTaskDetailDrawer = ({
  setSelectedTask,
  selectedTask,
  onReassignTask,
  openTaskStepIds,
}: WorkflowDetailsDrawerProps) => {
  const {
    v2: {
      userInfo: { user },
    },
  } = useAuthenticatedContext();
  const queryClient = useQueryClient();
  const { addAlert } = useAlert();
  const [selectedChoiceId, setSelectedChoiceId] = useState<string | null>(null);

  const workflowDetailsEnabled = Boolean(selectedTask?.workflow_id);
  const workflowId = selectedTask?.workflow_id ?? "";
  const workflowQuery = useGetOneWorkflow(
    { id: workflowId },
    { enabled: Boolean(workflowId) }
  );
  const workflowDetails = workflowQuery.data?.data;
  const workflowDetailsLoading = workflowQuery.isLoading;

  const taskId = selectedTask?.id ?? "";
  const workflowTaskQuery = useGetOneWorkflowTask(
    { id: taskId },
    { enabled: Boolean(taskId) }
  );
  const workflowTaskDetails = workflowTaskQuery.data?.data;

  const { formSchemaId } = getFormSchemaId(selectedTask, workflowDetails);
  const formSchemaQuery = useGetOneFormSchema(
    { id: formSchemaId },
    { enabled: Boolean(formSchemaId) }
  );
  const formSchemaDetails = formSchemaQuery.data?.data;

  const { data: userGroupsData } = useListUserGroups(
    { filters: { "user.id": [user?.id] } },
    { enabled: !!user }
  );
  const userGroups = userGroupsData?.data ?? [];

  const { isLoading: isLoadingComplete, mutate: completeTask } = useMutation({
    mutationFn: async () => {
      if (!selectedTask) {
        return;
      }

      await WorkflowTaskAdapter.updateOne({
        id: selectedTask.id,
        data: {
          status: WorkflowTaskStatus.COMPLETE,
          ...(selectedChoiceId ? { choice: selectedChoiceId } : {}),
        },
        previousData: selectedTask,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workflows"] });
      queryClient.invalidateQueries({
        queryKey: [Resources.WORKFLOW],
      });
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: ["workflows"],
        });
        queryClient.refetchQueries({
          queryKey: [Resources.WORKFLOW],
        });
      }, 4000);
      onClose();
      addAlert?.({
        variant: "success",
        message: "Successfully updated task status to complete",
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to update status. Please try again.",
      });
    },
  });

  const { isLoading: isLoadingDismissed, mutate: dismissTask } = useMutation({
    mutationFn: async () => {
      if (!selectedTask) {
        return;
      }

      await WorkflowTaskAdapter.updateOne({
        id: selectedTask.id,
        data: {
          status: WorkflowTaskStatus.DISMISSED,
        },
        previousData: selectedTask,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workflows"] });
      queryClient.invalidateQueries({
        queryKey: [Resources.WORKFLOW],
      });
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: ["workflows"],
        });
        queryClient.refetchQueries({
          queryKey: [Resources.WORKFLOW],
        });
      }, 4000);
      addAlert?.({
        variant: "success",
        message: "Successfully updated task status to dismissed",
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to dismiss task.",
      });
    },
  });
  const onClose = () => setSelectedTask(null);

  const getChoices = (
    workflowDetails?: WorkflowType,
    workflowTaskDetails?: WorkflowTaskType
  ) => {
    const stepId = workflowTaskDetails?.step_id;
    if (
      stepId &&
      workflowDetails?.config.steps[stepId].task.type ===
        WorkflowTaskTypes.CHOICE
    ) {
      return workflowDetails?.config.steps[stepId].task.choices;
    }
    return [];
  };

  const choices = getChoices(workflowDetails, workflowTaskDetails);

  useEffect(() => {
    if (choices.length > 0) {
      if (workflowTaskDetails?.status === WorkflowTaskStatus.OPEN) {
        setSelectedChoiceId(choices[0].id);
      } else if (workflowTaskDetails?.status === WorkflowTaskStatus.COMPLETE) {
        const selectedChoice = choices.find((choice) =>
          openTaskStepIds.includes(choice.next)
        );
        setSelectedChoiceId(selectedChoice?.id ?? null);
      }
    } else {
      setSelectedChoiceId(null);
    }
  }, [choices]);

  const isLoading = isLoadingComplete || isLoadingDismissed;

  const isTaskFormRequiredWithNoRelevantSubmissions = useMemo(() => {
    const workflowTask = workflowTaskDetails ?? selectedTask;
    const hasTaskAnySubmissionsOfSameSchema =
      !!workflowTask &&
      workflowTask?.type === WorkflowTaskTypes.SUBMIT_FORM &&
      Array.isArray(workflowTask?.form_submission) &&
      workflowTask?.form_submission.length > 0 &&
      workflowTask?.form_submission.some(
        (submission) => submission.form_schema.id === formSchemaId
      );

    const doesCurrentStepRequireFormSubmission =
      !!workflowDetails?.config.steps?.[selectedTask?.step_id ?? ""]?.task
        ?.form_required;

    return (
      doesCurrentStepRequireFormSubmission && !hasTaskAnySubmissionsOfSameSchema
    );
  }, [workflowTaskDetails, workflowDetails, selectedTask, formSchemaId]);

  const checkIfUserAssignedToTask = () => {
    const isUserInAssigneeGroup = userGroups.some(
      (userGroup) => userGroup.id === selectedTask?.assignee_group
    );
    const isUserAssignee = user?.id === selectedTask?.assignee_user;

    return isUserAssignee || isUserInAssigneeGroup;
  };

  const isTaskAssigneeRestrictedAndUserNotAssignee =
    (selectedTask?.restrict_assignee_completion &&
      !checkIfUserAssignedToTask()) ??
    false;

  const isCompleteButtonDisabled =
    (workflowDetailsEnabled && workflowDetailsLoading) ||
    selectedTask?.status !== WorkflowTaskStatus.OPEN ||
    isTaskFormRequiredWithNoRelevantSubmissions ||
    isTaskAssigneeRestrictedAndUserNotAssignee;

  const completeButton = (
    <ButtonWithPopover
      key="complete-task-button"
      label={
        isTaskAssigneeRestrictedAndUserNotAssignee
          ? "Only assignees can action this task"
          : ""
      }
      variant="primary"
      onClick={completeTask}
      isLoading={isLoading}
      buttonProps={{
        disabled: isCompleteButtonDisabled,
      }}
      popoverProps={{ positions: ["top"] }}
    >
      Complete Task
    </ButtonWithPopover>
  );

  const completeButtonComponent =
    isTaskFormRequiredWithNoRelevantSubmissions ? (
      <Tooltip
        content={`At least one form submission ${formSchemaDetails?.name ? `of form template "${formSchemaDetails?.name}"` : ""} is required to complete the task.`}
      >
        {completeButton}
      </Tooltip>
    ) : (
      completeButton
    );

  return (
    <Drawer
      size="md"
      isOpen={!!selectedTask}
      onClose={onClose}
      title="Task Details"
      actionRow={
        <div className={cx("buttonContainer")}>
          <Button
            isLoading={isLoading}
            variant="error-outline"
            onClick={dismissTask}
            disabled={selectedTask?.status !== WorkflowTaskStatus.OPEN}
          >
            Dismiss
          </Button>
          {selectedTask ? (
            <Button
              isLoading={isLoading}
              onClick={() => {
                onReassignTask?.(selectedTask);
                setSelectedTask(null);
              }}
              disabled={selectedTask.status !== WorkflowTaskStatus.OPEN}
            >
              Re-Assign
            </Button>
          ) : null}
          {completeButtonComponent}
        </div>
      }
    >
      <div className={cx("contentContainer")}>
        <h6 className={cx("title")}>{selectedTask?.name}</h6>
        <WorkflowTaskDetailStatusKeyValueList selectedTask={selectedTask} />
        <WorkflowTaskDetailKeyValueList
          selectedTask={selectedTask}
          formSchema={formSchemaDetails}
        />
        {selectedTask?.type === WorkflowTaskTypes.SUBMIT_FORM ? (
          <WorkflowTaskFormSubmission
            workflowTask={workflowTaskDetails ?? selectedTask}
            formSchema={formSchemaDetails}
            workflow={workflowDetails}
          />
        ) : null}
        {selectedTask?.type === WorkflowTaskTypes.COMPLETE_EVENT ? (
          <WorkflowTaskCompleteEvent
            workflowTask={workflowTaskDetails ?? selectedTask}
            workflow={workflowDetails}
          />
        ) : null}
        {selectedTask?.type === WorkflowTaskTypes.CHOICE &&
        selectedChoiceId &&
        workflowTaskDetails ? (
          <WorkflowTaskUserChoice
            choices={choices}
            selectedChoiceId={selectedChoiceId}
            setSelectedChoiceId={setSelectedChoiceId}
            taskStatus={workflowTaskDetails.status}
          />
        ) : null}
      </div>
    </Drawer>
  );
};
