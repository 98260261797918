import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
  IdAndNameSchema,
} from "./DomainModelSchemas";

export const LegacyUserClaimSchema = z
  .object({
    name: z.string(),
    email: z.string().email(),
    site_ids: z.array(z.number()),
  })
  .merge(DomainModelSchema);

export const UserStatus = {
  active: "active",
  disabled: "disabled",
} as const;

export type UserStatusType = (typeof UserStatus)[keyof typeof UserStatus];

export const UserSchema = z
  .object({
    name: z.string(),
    quicksight: z.boolean(),
    // IMPROVE: make this an enum
    timezone: z.string(),
    // IMPROVE: add phone number regex (depends on country though...which is a data point we currently don't track)
    status: z.enum([UserStatus.active, UserStatus.disabled]),
    email: z.string().email(),
    company_membership: z.array(
      z
        .object({
          company_id: z.string().uuid(),
        })
        .merge(DomainModelMetaSchema)
        .merge(DomainModelMetaExtendedSchema)
    ),
    user_groups: z.array(IdAndNameSchema),
    user_groups_count: z.number(),
    roles: z.array(IdAndNameSchema),
    roles_count: z.number(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a User model in the Node API");

export const UserCreateSchema = UserSchema.pick({
  name: true,
  email: true,
  timezone: true,
  quicksight: true,
});

export const UserUpdateSchema = UserSchema.pick({
  name: true,
  timezone: true,
  quicksight: true,
}).partial();

export type LegacyUserClaimType = z.infer<typeof LegacyUserClaimSchema>;
export type UserType = z.infer<typeof UserSchema>;
export type UserWithCompaniesType = UserType & {
  companies: Array<{ id: string; name: string }>;
};
export type UserCreateType = z.infer<typeof UserCreateSchema>;
export type UserUpdateType = z.infer<typeof UserUpdateSchema>;
