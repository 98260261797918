import {
  useEnhanceRecordResources,
  useGetOneRecord,
} from "#src/components/hooks/adapters/useRecords";
import { useGetOneUser } from "#src/components/hooks/adapters/useUsers";
import { getSourceName, getSourcePath } from "#src/utils/recordUtils";
import { AssetType, RecordType } from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import { useState } from "react";

export type RecordValueDrawerStateType = {
  record_id: string;
  year_month: string;
  asset_id: string;
  measurement_type: string;
};

export const defaultRecordValueDrawerState = {
  record_id: "",
  measurement_type: "",
  year_month: "",
  asset_id: "",
};

export const useUpdateUserInfo = (fullRecord?: RecordType) => {
  const usersQuery = useGetOneUser({ id: fullRecord?.updated_by });
  const updatedBy = usersQuery.data?.name ?? "Unknown User";
  const updatedAt = fullRecord?.updated_at
    ? datetimeFormatter(new Date(fullRecord.updated_at)).replace(",", "")
    : "-";

  return { updatedAt, updatedBy, isLoading: usersQuery.isLoading };
};

export const useRecordSourceParser = (
  record?: RecordType,
  measurement_type?: string
) => {
  const findRecordValue = record?.values.find(
    (value) => measurement_type === value.measurement_type
  );

  const recordValueSources = findRecordValue?.configuration.sources;

  const sources = new Array<{
    name: string;
    url: string;
  }>();
  if (recordValueSources) {
    recordValueSources.forEach((source) => {
      sources.push({
        name:
          getSourceName(
            findRecordValue.configuration.configuration_type,
            source
          ) ?? "Unknown Estimation Method",
        url:
          getSourcePath(
            findRecordValue.configuration.configuration_type,
            source
          ) ?? "",
      });
    });
  }
  return sources;
};

export const useFetchRecordWithEnhancedSources = (recordId?: string) => {
  const recordQueryEnabled = !!recordId;

  const recordQuery = useGetOneRecord(
    { recordId: recordId ?? "" },
    { enabled: recordQueryEnabled }
  );
  const unenhancedRecord = recordQuery?.data;

  const { recordOrRecords: recordWithSources, isLoading: isEnhancing } =
    useEnhanceRecordResources(unenhancedRecord, {
      enabled: !!unenhancedRecord,
    });

  const isLoading =
    (recordQueryEnabled && recordQuery.isLoading) ||
    (!!unenhancedRecord && isEnhancing);

  return { record: recordWithSources, isLoading };
};

export const useNextPrevNavigate = (
  items: RecordType[],
  assetIdFunction: (nextPrevItem?: RecordType) => string,
  drawerState: RecordValueDrawerStateType,
  setRecordValueDrawerState?: React.Dispatch<
    React.SetStateAction<RecordValueDrawerStateType | undefined>
  >
) => {
  const activeMeasurementType = drawerState?.measurement_type;
  const itemsHavingCurrentMeasurementType = items.filter((item) => {
    const findValue = item.values.find(
      (value) => value.measurement_type === activeMeasurementType
    );
    return findValue && (findValue.value || findValue.value === 0);
  });

  const myIndex = itemsHavingCurrentMeasurementType.findIndex(
    ({ id }) => id === drawerState?.record_id
  );

  const noPrev = myIndex === 0;
  const noNext = myIndex === itemsHavingCurrentMeasurementType.length - 1;

  const goNext = () => {
    if (noNext) return;
    const nextItem = itemsHavingCurrentMeasurementType[myIndex + 1];
    if (nextItem) {
      setRecordValueDrawerState?.({
        measurement_type: drawerState?.measurement_type ?? "",
        year_month: nextItem.year_month,
        record_id: nextItem.id,
        asset_id: assetIdFunction(nextItem),
      });
    }
  };

  const goPrev = () => {
    if (noPrev) return;
    const prevItem = itemsHavingCurrentMeasurementType[myIndex - 1];
    if (prevItem) {
      setRecordValueDrawerState?.({
        measurement_type: drawerState?.measurement_type ?? "",
        year_month: prevItem.year_month,
        record_id: prevItem.id,
        asset_id: assetIdFunction(prevItem),
      });
    }
  };

  return { noNext, noPrev, goNext, goPrev };
};

export const useRecordValueDrawer = ({
  items,
  itemToAssetIdFunction,
}: {
  items: RecordType[];
  itemToAssetIdFunction: (nextPrevItem?: RecordType) => string;
}) => {
  const [drawerState, setDrawerState] = useState<RecordValueDrawerStateType>();

  const onClose = () => setDrawerState(undefined);

  const activeMeasurementType = drawerState?.measurement_type;
  const itemsHavingCurrentMeasurementType = items.filter((item) => {
    const findValue = item.values.find(
      (value) => value.measurement_type === activeMeasurementType
    );
    return findValue && (findValue.value || findValue.value === 0);
  });

  const myIndex = itemsHavingCurrentMeasurementType.findIndex(
    ({ id }) => id === drawerState?.record_id
  );

  const noPrev = myIndex === 0;
  const noNext = myIndex === itemsHavingCurrentMeasurementType.length - 1;

  const goNext = () => {
    if (noNext) return;
    const nextItem = itemsHavingCurrentMeasurementType[myIndex + 1];
    if (nextItem) {
      setDrawerState?.({
        year_month: nextItem.year_month,
        record_id: nextItem.id,
        asset_id: itemToAssetIdFunction(nextItem),
        measurement_type: drawerState?.measurement_type ?? "",
      });
    }
  };

  const goPrev = () => {
    if (noPrev) return;
    const prevItem = itemsHavingCurrentMeasurementType[myIndex - 1];
    if (prevItem) {
      setDrawerState?.({
        year_month: prevItem.year_month,
        record_id: prevItem.id,
        asset_id: itemToAssetIdFunction(prevItem),
        measurement_type: drawerState?.measurement_type ?? "",
      });
    }
  };

  const recordValue = items
    .find(
      ({ id, year_month }) =>
        id === drawerState?.record_id && year_month === drawerState?.year_month
    )
    ?.values.find(
      ({ measurement_type }) =>
        measurement_type === drawerState?.measurement_type
    );

  const openDrawer = (
    asset_id: string,
    record_id: string,
    year_month: string,
    measurement_type: string
  ) => {
    setDrawerState({
      year_month,
      record_id,
      asset_id,
      measurement_type,
    });
  };

  return {
    drawerState,
    onClose,
    recordValue,
    navigationProps: { noNext, noPrev, goNext, goPrev },
    openDrawer,
  };
};
