import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { UserSchema } from "./UserSchemas";

export const RoleStatus = {
  active: "active",
  disabled: "disabled",
} as const;

export type RoleStatusType = (typeof RoleStatus)[keyof typeof RoleStatus];

export const RoleSchema = z
  .object({
    name: z.string(),
    description: z.string(),
    status: z.enum([RoleStatus.active, RoleStatus.disabled]),
    company_id: z.string().uuid(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a Group model in the Node API");

export const UserRoleAssignmentSchema = z
  .object({
    company_id: z.string().uuid(),
    user_id: z.string().uuid(),
    /**
     * @deprecated use `role_id` instead
     */
    group_id: z.string().uuid(),
    /**
     * @deprecated use `role` instead
     */
    group: RoleSchema.pick({
      id: true,
      name: true,
    }),
    role_id: z.string().uuid(),
    role: RoleSchema.pick({
      id: true,
      name: true,
    }),
  })
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a User's Group assignment model in the Node API");

export const RoleUserMembershipSchema = z
  .object({
    group_id: z.string().uuid(),
    user_id: z.string().uuid(),
    user: UserSchema.pick({
      id: true,
      name: true,
      email: true,
    }),
  })
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a Group's User membership model in the Node API");

export const RoleCreateSchema = RoleSchema.pick({
  name: true,
  description: true,
}).partial({
  description: true,
});

export const RoleUpdateSchema = RoleSchema.pick({
  name: true,
  description: true,
}).partial();

export type RoleType = z.infer<typeof RoleSchema>;
export type UserRoleAssignmentType = z.infer<typeof UserRoleAssignmentSchema>;
export type RoleUserMembershipType = z.infer<typeof RoleUserMembershipSchema>;
export type RoleUpdateType = z.infer<typeof RoleUpdateSchema>;
export type RoleCreateType = z.infer<typeof RoleCreateSchema>;
