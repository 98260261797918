import { useClearRecordCache } from "#src/components/hooks/adapters/useRecords";
import { useStickyState } from "#src/hooks/useStickyState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import {
  DefaultConfigurationFilterType,
  RecordDomain,
} from "@validereinc/domain";

export const useApplyDefaultRecordConfigurationAsync = (jobKey: string) => {
  const { addAlert } = useAlert();
  const [_, setJobId] = useStickyState<string>("", jobKey);

  return useMutation({
    mutationFn: ({
      filters,
      overwriteAllValues,
    }: {
      filters: DefaultConfigurationFilterType;
      overwriteAllValues: boolean;
    }) =>
      RecordDomain.applyDefaultConfigurationAsync(filters, overwriteAllValues),
    onSuccess: (result) => {
      setJobId(result.job.id);
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to run automations",
      });
    },
  });
};

export const useApplyDefaultRecordConfiguration = () => {
  const { addAlert } = useAlert();
  const { invalidate } = useClearRecordCache();

  return useMutation({
    mutationFn: ({
      filters,
      overwriteAllValues,
    }: {
      filters: DefaultConfigurationFilterType;
      overwriteAllValues: boolean;
    }) => RecordDomain.applyDefaultConfiguration(filters, overwriteAllValues),
    onSuccess: (data) => {
      invalidate();
      const updatedCount = Object.values(data?.data).reduce(
        (
          {
            recordValueSuccessCounter,
            recordSuccessCounter,
            recordSkippedCounter,
          },
          { upserts, status }
        ) => {
          if (status === "success") {
            recordValueSuccessCounter += upserts.length;
            recordSuccessCounter += 1;
          }
          if (status === "skipped") {
            recordSkippedCounter += 1;
          }
          return {
            recordValueSuccessCounter,
            recordSuccessCounter,
            recordSkippedCounter,
          };
        },
        {
          recordValueSuccessCounter: 0,
          recordSuccessCounter: 0,
          recordSkippedCounter: 0,
        }
      );

      if (updatedCount.recordValueSuccessCounter > 0) {
        const singleOrPluralRecords =
          updatedCount.recordSuccessCounter === 1
            ? "for one record"
            : `across ${updatedCount.recordSuccessCounter} records`;

        const singleOrPluralRecordValues =
          updatedCount.recordValueSuccessCounter === 1
            ? "one record value"
            : `${updatedCount.recordValueSuccessCounter} record values`;

        addAlert({
          variant: "success",
          message: `Successfully applied record automation to ${singleOrPluralRecordValues} ${singleOrPluralRecords}.`,
        });
      }
      if (updatedCount.recordSkippedCounter > 0) {
        const singleOrPluralRecords =
          updatedCount.recordSkippedCounter === 1
            ? "one record"
            : `${updatedCount.recordSkippedCounter} records`;
        addAlert({
          variant: "normal",
          message: `Skipped applying record automation to ${singleOrPluralRecords}.`,
        });
      }
      if (data.skippedCount > 0) {
        addAlert({
          variant: "error",
          message: `Failed to apply record automation for ${data.skippedCount} record value(s) because they are locked.`,
        });
      }
    },
    onError: (error) => {
      console.error(error);
      addAlert({
        variant: "error",
        message: "Unable to apply record automation.",
      });
    },
  });
};

export const useClearRecordsCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({ queryKey: ["records"] });
    },
  };
};
