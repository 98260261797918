import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import {
  FilterArea,
  useFilterAreaContentContext,
} from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useQueryClient } from "@tanstack/react-query";
import {
  DateSelectorInput,
  DropdownInput,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  ReportingGroupDomain,
  Resources,
  type GetListResponseType,
  type ReportingGroupType,
} from "@validereinc/domain";
import { sortReportingGroupsByPriority } from "@validereinc/domain-controllers/logic/reporting-groups";
import React from "react";

export const FILTER_CONFIG = {
  dateRange: {
    name: "date_range",
  },
  reportingScenario: {
    name: "reporting_group_id",
  },
  recordMode: {
    name: "record_mode",
  },
} as const;

const RecordsViewFilterAreaContent = ({
  isPeriodDateRange,
  isPeriodAvailable,
}: {
  isPeriodDateRange: boolean;
  isPeriodAvailable: boolean;
}) => {
  const { handleOnChange } = useFilterAreaContentContext();

  return (
    <div style={{ marginRight: 8, display: "flex", gap: 8 }}>
      <ReportingGroupFilterSwitcher
        name={FILTER_CONFIG.reportingScenario.name}
        onChange={(val) =>
          handleOnChange(val, FILTER_CONFIG.reportingScenario.name)
        }
      />
      {isPeriodAvailable ? (
        <DateSelectorInput
          name={FILTER_CONFIG.dateRange.name}
          variant="month"
          isRange={isPeriodDateRange}
          isInline
          onChange={(val) => handleOnChange(val, FILTER_CONFIG.dateRange.name)}
        />
      ) : null}
    </div>
  );
};

export const RecordsViewFilterArea = ({
  viewConfigStorageKey,
  isPeriodDateRange,
  isPeriodAvailable,
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  isPeriodDateRange: boolean;
  isPeriodAvailable: boolean;
}) => {
  const queryClient = useQueryClient();

  return (
    <FilterArea.Root
      storageKey={viewConfigStorageKey}
      defaultValues={async () => {
        const reportingGroupsQueryFromCache = queryClient.getQueryData<
          GetListResponseType<ReportingGroupType>
        >([Resources.REPORTING_GROUP]);
        let defaultReportingGroup = "";

        if (reportingGroupsQueryFromCache?.data) {
          defaultReportingGroup =
            sortReportingGroupsByPriority(reportingGroupsQueryFromCache.data)[0]
              ?.id ?? "";
        } else {
          const reportingGroups = await ReportingGroupDomain.getList({});

          queryClient.setQueryData(
            [Resources.REPORTING_GROUP],
            reportingGroups
          );

          defaultReportingGroup =
            sortReportingGroupsByPriority(reportingGroups.data)[0]?.id ?? "";
        }

        return {
          ...(isPeriodAvailable
            ? {
                [FILTER_CONFIG.dateRange.name]: isPeriodDateRange
                  ? DEFAULT_DATE_RANGES.currentWholeYear
                  : DEFAULT_DATE_RANGES.lastWholeMonth,
              }
            : {}),
          [FILTER_CONFIG.reportingScenario.name]: defaultReportingGroup,
        };
      }}
      applyDefaultValues
    >
      <FilterArea.Container>
        <FilterArea.Content>
          {(props) => (
            <RecordsViewFilterAreaContent
              isPeriodDateRange={isPeriodDateRange}
              isPeriodAvailable={isPeriodAvailable}
              {...props}
            />
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const RecordsTableFilterAreaDrawerContent = ({
  selectableProperties,
}: {
  selectableProperties?: string[];
}) => {
  const { getTypeName } = useMeasurementTypes();
  const propertySelectorValues = selectableProperties?.map((key) => ({
    key,
    name: getTypeName(key),
  }));

  return (
    <>
      <DropdownInput
        name="measurement_type"
        label="Measurement Types"
        options={propertySelectorValues}
        placeholder="Select Property..."
        labelKey="name"
        valueKey="key"
        isSearchable
        isOptionalTextShown={false}
      />
    </>
  );
};

export const RecordsTableFilterArea = ({
  filterConfigStorageKey,
  selectableProperties,
}: {
  selectableProperties?: string[];
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterArea.Root storageKey={filterConfigStorageKey}>
      <FilterArea.Container aria-label="Filters for Records">
        <FilterDrawer.Root>
          <FilterDrawer.Trigger />
          <FilterDrawer.Content>
            <RecordsTableFilterAreaDrawerContent
              selectableProperties={selectableProperties}
            />
          </FilterDrawer.Content>
        </FilterDrawer.Root>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
