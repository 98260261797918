import { useSearchParams } from "#routers/hooks";
import { CalculatorsEmissionsTab } from "#routes/calculations/calculators/CalculatorsEmissionsTab";
import { CalculatorsNetworkTab } from "#routes/calculations/calculators/CalculatorsNetworkTab";
import { CalculatorsVolumeTab } from "#routes/calculations/calculators/CalculatorsVolumeTab";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";
import { CalculationsRoutePath } from "../";

export const CalculatorsPage = () => {
  const [breadcrumbs] = useBreadcrumbsFromRoute(CalculationsRoutePath);
  const [{ tab }, setSearchParams] = useSearchParams();

  const [isFlowVolumeEnabled, isLoadingFlowVolumeEnabled] =
    useIsFeatureAvailable({
      featureFlagQuery: "core:flows",
      permissionQuery: { $and: ["flows:read", "records:read"] },
    });
  const [isNetworkProrationEnabled, isLoadingNetworkProrationEnabled] =
    useIsFeatureAvailable({
      featureFlagQuery: "core:networks",
      permissionQuery: "networks:read",
    });
  const [isEquipmentEmissionsEnabled, isLoadingEquipmentEmissionsEnabled] =
    useIsFeatureAvailable({
      featureFlagQuery: "core:equipment",
      permissionQuery: { $and: ["equipment:read", "calculator_results:read"] },
    });

  return (
    <Page
      title={CalculationsRoutePath.title}
      isLoading={
        isLoadingFlowVolumeEnabled ||
        isLoadingNetworkProrationEnabled ||
        isLoadingEquipmentEmissionsEnabled
      }
      breadcrumbs={breadcrumbs}
      activeTabKey={tab ?? (isFlowVolumeEnabled ? "volume" : "emissions")}
      onActiveTabKeyChange={(tab) => setSearchParams({ tab })}
    >
      {isFlowVolumeEnabled ? (
        <Tab
          title="Volume"
          tabKey="volume"
        >
          <CalculatorsVolumeTab />
        </Tab>
      ) : null}
      {isNetworkProrationEnabled ? (
        <Tab
          title="Networks"
          tabKey="networks"
        >
          <CalculatorsNetworkTab />
        </Tab>
      ) : null}
      {isEquipmentEmissionsEnabled ? (
        <Tab
          title="Emissions"
          tabKey="emissions"
        >
          <CalculatorsEmissionsTab />
        </Tab>
      ) : null}
    </Page>
  );
};
