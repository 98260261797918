import { useNavigate } from "#src/Routers/hooks";
import config from "#src/config";
import {
  Button,
  EmptyState,
  EmptyStateProps,
  Icon,
  Link,
} from "@validereinc/common-components";
import { BaseError } from "@validereinc/domain";
import classNames from "classnames/bind";
import React from "react";
import styles from "./UnknownError.module.css";
import { HomeRoute } from "#src/routes/home";

const cx = classNames.bind(styles);

export const UnknownErrorLayout = ({
  error,
  action: providedAction,
}: { error: unknown } & Pick<EmptyStateProps, "action">) => {
  const navigate = useNavigate();

  const attemptToGetErrorCode = (error: unknown): string | null => {
    if (error instanceof BaseError) {
      return error.code as string;
    }

    return null;
  };

  const attemptToGetErrorMessage = (error: unknown): string => {
    if (error instanceof BaseError) {
      return error.message || error.getExplanation();
    }

    if (error instanceof Error) {
      const normalizedErr = BaseError.normalize(error);

      return normalizedErr.message;
    }

    return "n/a";
  };

  const errCode = attemptToGetErrorCode(error);
  const errMessage = attemptToGetErrorMessage(error);
  const action =
    typeof providedAction !== "undefined" ? (
      providedAction
    ) : (
      <Button
        variant="primary"
        onClick={() => {
          navigate({
            pathname: HomeRoute.path,
          });
        }}
      >
        Go to Homepage
      </Button>
    );

  return (
    <EmptyState
      icon={<Icon variant="smiley-sad" />}
      variant="error"
      title="We're sorry, something went wrong."
      suggestion={({ className }) => (
        <p className={className}>
          <Link
            anchorTagProps={{
              href: `mailto:${config.SUPPORT_EMAIL}?subject=${
                errCode
                  ? `Bug Report: Unknown error (code: ${errCode}, details: ${errMessage}) in the Validere Web App`
                  : `Bug Report: Unknown error (details: ${errMessage}) in the Validere Web App`
              }`,
            }}
            label="Contact support"
          />
          &nbsp;to let us know what happened.
        </p>
      )}
      className={cx("container")}
      action={action}
    />
  );
};
