import {
  Accordion,
  Drawer,
  KeyValueList,
  Pill,
  Icon,
} from "@validereinc/common-components";
import React, { ReactNode } from "react";
import type { DrawerProps } from "@validereinc/common-components";
import classNames from "classnames/bind";
import styles from "./TemplatedConfigurationRunDrawer.module.scss";
import { TemplatedConfigurationRunStatus } from "@validereinc/domain";
import { dateFormatter } from "@validereinc/utilities";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  navigateToResource,
  getResourceTypeDisplayName,
  canLinkToResource,
  getResourceDisplayName,
} from "./TemplatedConfigurationRunDrawer.helper";
import {
  TemplatedConfigurationRunDependencyGraphResponseType,
  TemplatedConfigurationDependencyGraphResourceNodeType,
  TemplatedConfigurationResourceStatus,
  UsersAdapter,
} from "@validereinc/domain";
import { useQuery } from "@tanstack/react-query";

const cx = classNames.bind(styles);

const { AccordionPanel } = Accordion;
export const TemplatedConfigurationRunDrawer = ({
  isOpen,
  onClose,
  templatedConfigurationRunDependencyGraph,
  resourceId,
}: Pick<DrawerProps, "isOpen" | "onClose"> & {
  templatedConfigurationRunDependencyGraph?: TemplatedConfigurationRunDependencyGraphResponseType;
  resourceId: string;
}) => {
  if (!templatedConfigurationRunDependencyGraph) {
    return null;
  }

  const { data: submittedByUser } = useQuery({
    queryKey: ["users", templatedConfigurationRunDependencyGraph.updated_by],
    queryFn: () =>
      UsersAdapter.getOne({
        id: templatedConfigurationRunDependencyGraph.updated_by,
      }),
    enabled: !!templatedConfigurationRunDependencyGraph.updated_by,
  });

  const runData: Array<{ title: string; value: ReactNode }> = [
    {
      title: "Submitted By",
      value: submittedByUser?.data.name,
    },
    {
      title: "Date Submitted",
      value: dateFormatter(
        new Date(templatedConfigurationRunDependencyGraph.created_at)
      ),
    },
  ];

  const primaryResourceData: Array<{ title: string; value: ReactNode }> = [
    {
      title: getResourceTypeDisplayName(
        templatedConfigurationRunDependencyGraph.primary_resource.type,
        true
      ),
      value: templatedConfigurationRunDependencyGraph.primary_resource.name,
    },
  ];

  return (
    <Drawer
      title="Configuration Submission"
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      actionRow={[]}
    >
      <div className={cx("contentContainer")}>
        <div className={cx("titleContainer")}>
          <h6 className={cx("title")}>
            {templatedConfigurationRunDependencyGraph.name}
          </h6>
          {templatedConfigurationRunDependencyGraph.status ? (
            <Pill
              variant={
                templatedConfigurationRunDependencyGraph.status ===
                TemplatedConfigurationRunStatus.SUCCESS
                  ? "success"
                  : templatedConfigurationRunDependencyGraph.status ===
                      TemplatedConfigurationRunStatus.FAILED
                    ? "error"
                    : templatedConfigurationRunDependencyGraph.status ===
                        TemplatedConfigurationRunStatus.PARTIAL
                      ? "warning"
                      : "default"
              }
            >
              {templatedConfigurationRunDependencyGraph.status}
            </Pill>
          ) : null}
        </div>
        <KeyValueList
          data={runData}
          className={cx("keyValueListContainer")}
        />
        <div className={cx("resourceStatusCountContainer")}>
          <Pill variant="info">
            All:&nbsp;
            {templatedConfigurationRunDependencyGraph.resource_status_counts
              .success +
              templatedConfigurationRunDependencyGraph.resource_status_counts
                .failed +
              templatedConfigurationRunDependencyGraph.resource_status_counts
                .submitted}
          </Pill>
          <Pill variant="success">
            Created:&nbsp;
            {
              templatedConfigurationRunDependencyGraph.resource_status_counts
                .success
            }
          </Pill>
          <Pill variant="warning">
            Submitted:&nbsp;
            {
              templatedConfigurationRunDependencyGraph.resource_status_counts
                .submitted
            }
          </Pill>
          <Pill variant="error">
            Failed:&nbsp;
            {
              templatedConfigurationRunDependencyGraph.resource_status_counts
                .failed
            }
          </Pill>
        </div>
        <h6 className={cx("subtitle")}>Primary Resource Details</h6>
        <RoutingLink
          to={() =>
            navigateToResource(
              templatedConfigurationRunDependencyGraph.primary_resource.type,
              templatedConfigurationRunDependencyGraph.primary_resource.id
            )
          }
        >
          <KeyValueList
            data={primaryResourceData}
            className={cx("keyValueListContainer")}
          />
        </RoutingLink>
        <div>
          {templatedConfigurationRunDependencyGraph.primary_resource
            .associated_resources ? (
            <div>
              {Object.entries(
                templatedConfigurationRunDependencyGraph.primary_resource
                  .associated_resources
              ).map(([resourceType, resources]) => (
                <Accordion
                  key={resourceType}
                  defaultActiveKeys={[resourceType]}
                >
                  <AccordionPanel
                    key={resourceType}
                    dataKey={resourceType}
                    title={
                      <div
                        key={`title-${resourceType}`}
                        className={cx("accordionTitle")}
                      >
                        <p
                          key={`label-${resourceType}`}
                          className={cx("resourceTypeLabel")}
                        >
                          {getResourceTypeDisplayName(resourceType, true)}
                        </p>
                        <Pill variant="default">{resources.length}</Pill>
                      </div>
                    }
                  >
                    <ul className={cx("primaryAssociatedResourcesList")}>
                      {resources.map((associatedResource, i) => (
                        <li
                          key={associatedResource.resource_id}
                          className={
                            resourceId === associatedResource.id
                              ? cx("selectedResourceItem")
                              : i !== resources.length - 1
                                ? cx("resourceItem")
                                : ""
                          }
                        >
                          <Resource
                            resource={associatedResource}
                            linkedResource={
                              templatedConfigurationRunDependencyGraph.primary_resource
                            }
                            currentResourceId={resourceId}
                          />
                        </li>
                      ))}
                    </ul>
                  </AccordionPanel>
                </Accordion>
              ))}
            </div>
          ) : null}
        </div>
        {Object.keys(
          templatedConfigurationRunDependencyGraph?.additional_resources
        ).length > 0 ? (
          <>
            <hr
              key="additional-resources-divider"
              className={cx("divider")}
            />

            <Accordion
              key="additional-resources"
              defaultActiveKeys={["associated-resources"]}
            >
              <AccordionPanel
                key="additional-resources-panel"
                dataKey="additional-resources"
                title="Additional Resources"
              >
                <ul
                  key="additional-resources-list"
                  className={cx("associatedResourcesOuterList")}
                >
                  {Object.entries(
                    templatedConfigurationRunDependencyGraph.additional_resources
                  ).map(([resourceType, resources]) => (
                    <Accordion
                      key={`additional-resources-${resourceType}`}
                      defaultActiveKeys={[resourceType]}
                    >
                      <AccordionPanel
                        key={`additional-resources-${resourceType}`}
                        dataKey={resourceType}
                        title={
                          <div
                            key={`title-${resourceType}`}
                            className={cx("accordionTitle")}
                          >
                            <p
                              key={`label-${resourceType}`}
                              className={cx("resourceTypeLabel")}
                            >
                              {getResourceTypeDisplayName(resourceType, true)}
                            </p>
                            <Pill variant="default">{resources.length}</Pill>
                          </div>
                        }
                      >
                        <ul
                          key={`list-${resourceType}`}
                          className={cx("primaryAssociatedResourcesList")}
                        >
                          {resources.map((associatedResource, i) => (
                            <li
                              key={associatedResource.resource_id}
                              className={
                                resourceId === associatedResource.id
                                  ? cx("selectedResourceItem")
                                  : i !== resources.length - 1
                                    ? cx("resourceItem")
                                    : ""
                              }
                            >
                              <Resource
                                resource={associatedResource}
                                currentResourceId={resourceId}
                              />
                            </li>
                          ))}
                        </ul>
                      </AccordionPanel>
                    </Accordion>
                  ))}
                </ul>
              </AccordionPanel>
            </Accordion>
          </>
        ) : null}
      </div>
    </Drawer>
  );
};

export const Resource = ({
  resource,
  linkedResource,
  currentResourceId,
}: {
  resource: TemplatedConfigurationDependencyGraphResourceNodeType;
  linkedResource?: TemplatedConfigurationDependencyGraphResourceNodeType;
  currentResourceId: string;
}) => {
  return (
    <div>
      {resource.status === TemplatedConfigurationResourceStatus.FAILED ? (
        <p
          key={`failed-label-${resource.resource_id}`}
          className={cx("resourceFailedLabel")}
        >
          <Icon variant="warning-circle" />
          {getResourceDisplayName(resource)}
        </p>
      ) : resource.status === TemplatedConfigurationResourceStatus.SUBMITTED ? (
        <p
          key={`submitted-label-${resource.resource_id}`}
          className={cx("resourceSubmittedLabel")}
        >
          <Icon variant="spinner-gap" />
          {getResourceDisplayName(resource)}
        </p>
      ) : (
        <p
          key={`successful-label-${resource.resource_id}`}
          className={cx("resourceLabel")}
        >
          {canLinkToResource(resource, currentResourceId) ? (
            <RoutingLink
              to={() =>
                navigateToResource(
                  resource.type,
                  resource.id,
                  linkedResource?.type,
                  linkedResource?.id
                )
              }
            >
              {getResourceDisplayName(resource)}
            </RoutingLink>
          ) : (
            <>{getResourceDisplayName(resource)}</>
          )}
        </p>
      )}
      {resource.associated_resources ? (
        <>
          {Object.entries(resource.associated_resources).map(
            ([associatedResourceType, resources]) => (
              <div
                key={`container-${associatedResourceType}`}
                className={cx("associatedResourcesInnerListContainer")}
              >
                <Accordion
                  key={associatedResourceType}
                  defaultActiveKeys={[associatedResourceType]}
                >
                  <AccordionPanel
                    key={associatedResourceType}
                    dataKey={associatedResourceType}
                    title={
                      <div
                        key={`title-${associatedResourceType}`}
                        className={cx("accordionTitle")}
                      >
                        <p
                          key={`label-${associatedResourceType}`}
                          className={cx("resourceTypeLabel")}
                        >
                          {getResourceTypeDisplayName(
                            associatedResourceType,
                            true
                          )}
                        </p>
                        <Pill variant="default">{resources.length}</Pill>
                      </div>
                    }
                  >
                    <ul
                      key={`list-${associatedResourceType}`}
                      className={cx("associatedResourcesInnerList")}
                    >
                      {resources.map((associatedResource, i) => (
                        <li
                          key={associatedResource.resource_id}
                          className={
                            currentResourceId === associatedResource.id
                              ? cx("selectedResourceItem")
                              : i !== resources.length - 1
                                ? cx("resourceItem")
                                : ""
                          }
                        >
                          <Resource
                            resource={associatedResource}
                            linkedResource={resource}
                            currentResourceId={currentResourceId}
                          />
                        </li>
                      ))}
                    </ul>
                  </AccordionPanel>
                </Accordion>
              </div>
            )
          )}
        </>
      ) : null}
    </div>
  );
};
