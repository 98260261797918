import config from "#config";
import { fetchUserProfile } from "#src/components/Redux/actions";
import { fetchFeatureFlags } from "#src/components/Redux/actions/featureFlags";
import { fetchUserPermissions } from "#src/components/Redux/actions/permissions";
import store from "#src/components/Redux/store";
import UserService from "#src/components/Services/UserService";
import { ExceptionUtils } from "#src/utils/exception";
import { Auth0Client, IdToken } from "@auth0/auth0-spa-js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CompanyAdapter,
  CompanyFeatureFlagsAdapter,
  CompanyType,
  FeatureFlagAdapter,
  FeatureFlagType,
  LegacyFeatureFlagsAdapter,
  LegacyFeatureFlagsType,
  LegacyPermissionsType,
  LegacyUserType,
  LegacyUsersAdapter,
  PermissionType,
  UserWithCompaniesType,
  UsersAdapter,
  authorize,
} from "@validereinc/domain";
import isPlainObject from "lodash/isPlainObject";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import versionInfo from "../../VERSION.json";
import {
  AuthQueryType,
  createAuthorizationEvaluator,
  legacyCheckHasPermission,
  legacyCheckHasPermissionType,
  legacyCheckIsFeatureEnabled,
  legacyCheckIsFeatureEnabledType,
} from "./AuthenticatedContext.helpers";
import { jwtDecode, JwtPayload } from "jwt-decode";

const AuthenticatedContextAuthVersion = {
  v1: "v1",
  v2: "v2",
} as const;

export type AuthenticatedContextAuthVersionType =
  (typeof AuthenticatedContextAuthVersion)[keyof typeof AuthenticatedContextAuthVersion];

export type AuthenticatedContextActiveAuthVersionType =
  | "unknown"
  | AuthenticatedContextAuthVersionType[];

type DecodedJwtPayloadType = JwtPayload & Record<string, any>;

type AuthenticatedContextType = {
  /** the current app version */
  appVersion: string;
  /** is the current session authenticated? */
  isAuthenticated: boolean;
  /** is the current session's required authentication data still loading? */
  isLoading: boolean;
  /** the auth system(s) currently being leveraged */
  activeAuthVersion: AuthenticatedContextActiveAuthVersionType;
  /** the most essential claims being made when authenticating. Note, this is a
   * derived state - a consolidated look across all auth systems. */
  claims: {
    user: {
      id?: string;
    };
    company: {
      id?: string;
    };
  };
  /** call this function to switch companies */
  switchCompany: (companyId: string) => Promise<void>;
  /** call this function to log the user out */
  logout: () => void;
  /** call this function to take the user to the login page */
  login: () => void;
  /**
   * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
   * that is currently in use in legacy parts of the app
   */
  [AuthenticatedContextAuthVersion.v1]: {
    /** the custom claims in the JWT token from this API */
    claims: CustomClaimsType | null;
    /** the current authenticated user */
    user?: LegacyUserType;
    /** is currently authenticating? (includes getting claims, user details, permissions, and feature flags) */
    isLoading: boolean;
    /** the feature flags enabled for the authenticated user's company */
    featureFlags?: LegacyFeatureFlagsType;
    /** the permissions configured for the authenticated user */
    permissions?: LegacyPermissionsType;
    /** check if provided feature flags are enabled for the authenticated user's company */
    checkIsFeatureEnabled: ReturnType<legacyCheckIsFeatureEnabledType>;
    /** check if provided permissions are configured for the authenticated user */
    checkHasPermission: ReturnType<legacyCheckHasPermissionType>;
  };
  /** reflects the Node (CarbonHub) authorization API */
  [AuthenticatedContextAuthVersion.v2]: {
    /** the custom claims in the JWT token from this API */
    claims: CustomClaimsType | null;
    isLoading: boolean;
    featureFlags?: FeatureFlagType[];
    checkIsFeatureEnabled: (query: AuthQueryType) => boolean;
    userInfo: {
      user?: UserWithCompaniesType;
      permissions?: PermissionType[];
      checkHasPermissions: (query: AuthQueryType) => boolean;
    };
    companyInfo: {
      company?: CompanyType;
      featureFlags?: FeatureFlagType[];
      checkIsFeatureEnabled: (query: AuthQueryType) => boolean;
    };
  };
};

export const AuthenticatedContext =
  createContext<AuthenticatedContextType | null>(null);

/**
 * Custom claims set by the Node (CarbonHub) authorization API
 * Optional properties reflect old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
export type CustomClaimsType = {
  user: {
    id: string;
    email?: string;
    name?: string;
    site_ids?: number[];
  };
  entitlements?: LegacyPermissionsType;
  company: {
    id: string;
    int_id?: number;
    name?: string;
  };
};

/**
 * The Provider for the authenticated context. Loads the context with values.
 * @param param0.children react content to render
 * @returns the provider with children slotted within
 */
export const AuthenticatedContextProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customClaims, setCustomClaims] = useState<CustomClaimsType | null>(
    null
  );
  const [auth0Claims, setAuth0Claims] = useState<IdToken | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const queryClient = useQueryClient();

  // --- auth version agnostic data: application-specific information ---
  const { data: appVersion } = useQuery(
    ["app", "version"],
    async () => {
      const resp = await UserService.getAppVersion();

      if (versionInfo.version !== "PLACEHOLDER") {
        return versionInfo.version;
      }

      if (resp?.data?.version) {
        return resp.data.version;
      }

      return "n/a";
    },
    {
      staleTime: 60 * 60 * 1000,
      cacheTime: Infinity,
    }
  );

  // --- V1 data: reflects old monolith authorization system (Elixir API, OpsHub) ---
  const {
    data: legacyUser,
    isLoading: isLegacyUserLoading,
    isInitialLoading: isLegacyUserInitialLoading,
    errorUpdateCount: legacyUserErrorUpdateCount,
  } = useQuery(
    [
      "auth",
      AuthenticatedContextAuthVersion.v1,
      "users",
      customClaims?.user.id,
    ],
    () => LegacyUsersAdapter.self.get(),
    {
      enabled: isAuthenticated && Boolean(customClaims?.user.id),
      staleTime: 3 * 60 * 1000,
      cacheTime: Infinity,
      retry: false,
      meta: {
        hideErrorToasts: true,
      },
    }
  );

  const {
    data: legacyPermissions,
    isLoading: isLegacyPermissionsLoading,
    isInitialLoading: isLegacyPermissionsInitialLoading,
  } = useQuery(
    [
      "auth",
      AuthenticatedContextAuthVersion.v1,
      "permissions",
      customClaims?.user.id,
    ],
    () => LegacyUsersAdapter.self.getPermissions(),
    {
      enabled: isAuthenticated && Boolean(customClaims?.user.id),
      staleTime: 15 * 60 * 1000,
      cacheTime: Infinity,
      meta: {
        hideErrorToasts: true,
      },
    }
  );

  const {
    data: legacyFeatureFlags,
    isLoading: isLegacyFeatureFlagsLoading,
    isInitialLoading: isLegacyFeatureFlagsInitialLoading,
  } = useQuery(
    ["auth", AuthenticatedContextAuthVersion.v1, "featureFlags"],
    () => LegacyFeatureFlagsAdapter.getList(),
    {
      enabled: isAuthenticated && Boolean(customClaims?.user.id),
      select: (resp) => resp.features,
      staleTime: 15 * 60 * 1000,
      cacheTime: Infinity,
      meta: {
        hideErrorToasts: true,
      },
    }
  );

  // --- V2 data: reflects Node (CarbonHub) authorization API ---
  const {
    data: user,
    isLoading: isUserLoading,
    isInitialLoading: isUserInitialLoading,
    errorUpdateCount: userErrorUpdateCount,
  } = useQuery(
    [
      "auth",
      AuthenticatedContextAuthVersion.v2,
      "users",
      customClaims?.user.id ?? auth0Claims?.sub,
    ],
    async () => {
      if (customClaims?.user.id) {
        return UsersAdapter.myself.getOne();
      }
    },
    {
      enabled:
        isAuthenticated &&
        (Boolean(customClaims?.user.id) || Boolean(auth0Claims?.sub)),
      staleTime: 3 * 60 * 1000,
      cacheTime: Infinity,
      select: (resp) => resp?.data,
      retry: false,
      meta: {
        hideErrorToasts: true,
      },
    }
  );

  const {
    data: company,
    isLoading: isCompanyLoading,
    isInitialLoading: isCompanyInitialLoading,
  } = useQuery(
    [
      "auth",
      AuthenticatedContextAuthVersion.v2,
      "companies",
      customClaims?.company.id,
    ],
    async () => {
      const { data } = await CompanyAdapter.getOne({
        id: customClaims?.company.id ?? "",
      });
      return data;
    },
    {
      enabled: isAuthenticated && Boolean(customClaims?.company.id),
      staleTime: 15 * 60 * 1000,
      cacheTime: Infinity,
      meta: {
        hideErrorToasts: true,
      },
    }
  );

  const {
    data: globalFeatureFlags,
    isLoading: isGlobalFeatureFlagsLoading,
    isInitialLoading: isGlobalFeatureFlagsInitialLoading,
  } = useQuery(
    ["auth", AuthenticatedContextAuthVersion.v2, "featureFlags"],
    () => {
      return FeatureFlagAdapter.getList({});
    },
    {
      enabled: Boolean(isAuthenticated && user),
      staleTime: 15 * 60 * 1000,
      cacheTime: Infinity,
      // exponential back-off retry, min of 30s between retries on failure
      retryDelay: (attempt) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      meta: {
        hideErrorToasts: true,
      },
    }
  );

  const {
    data: companyFeatureFlags,
    isLoading: isCompanyFeatureFlagsLoading,
    isInitialLoading: isCompanyFeatureFlagsInitialLoading,
  } = useQuery(
    [
      "auth",
      AuthenticatedContextAuthVersion.v2,
      "companies",
      user?.company_id ?? company?.id,
      "featureFlags",
    ],
    () =>
      CompanyFeatureFlagsAdapter.company.getList({
        meta: {
          company_id: user?.company_id ?? company?.id,
        },
      }),
    {
      enabled: isAuthenticated && Boolean(user?.company_id ?? company?.id),
      staleTime: 15 * 60 * 1000,
      cacheTime: Infinity,
      // exponential back-off retry, min of 30s between retries on failure
      retryDelay: (attempt) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      meta: {
        hideErrorToasts: true,
      },
    }
  );

  const companyFeatureFlagsWithDetails = useMemo(() => {
    return companyFeatureFlags?.data.reduce<FeatureFlagType[]>(
      (enrichedList, companyFeatureFlag) => {
        const associatedFeatureFlagDetails = globalFeatureFlags?.data.find(
          (ff) => ff.name === companyFeatureFlag.feature_flag_name
        );

        if (!associatedFeatureFlagDetails) {
          return enrichedList;
        }

        enrichedList.push(associatedFeatureFlagDetails);
        return enrichedList;
      },
      []
    );
  }, [globalFeatureFlags, companyFeatureFlags]);

  const {
    data: permissions,
    isLoading: isPermissionsLoading,
    isInitialLoading: isPermissionsInitialLoading,
  } = useQuery(
    ["auth", AuthenticatedContextAuthVersion.v2, "permissions", user?.id],
    () =>
      UsersAdapter.permissions.getMany({
        userId: user?.id ?? "",
      }),
    {
      enabled: isAuthenticated && Boolean(user?.id),
      staleTime: 15 * 60 * 1000,
      cacheTime: Infinity,
      // exponential back-off retry, min of 30s between retries on failure
      retryDelay: (attempt) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      meta: {
        hideErrorToasts: true,
      },
    }
  );

  const auth0 = useMemo(
    () =>
      new Auth0Client({
        domain: config.DOMAIN,
        clientId: config.CLIENT_ID,
        cacheLocation: config.ENV !== "production" ? "localstorage" : "memory",
        authorizationParams: {
          redirect_uri: `${window.location.origin}/app/callback`,
          audience: config.API_ID,
          scope: "openid profile email offline_access",
        },
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
      }),
    [config]
  );

  // when there's no cached data and no query attempt has finished yet for v1 auth
  const v1IsLoading =
    isLoading ||
    isLegacyUserLoading ||
    isLegacyPermissionsLoading ||
    isLegacyFeatureFlagsLoading;
  // are any of the V1 auth requests, in-flight for the first time?
  const v1IsInitialLoading =
    (!legacyUserErrorUpdateCount && isLegacyUserInitialLoading) ||
    isLegacyPermissionsInitialLoading ||
    isLegacyFeatureFlagsInitialLoading;
  // when there's no cached data and no query attempt has finished yet for v2 auth
  const v2IsLoading =
    isLoading ||
    isUserLoading ||
    isGlobalFeatureFlagsLoading ||
    isCompanyFeatureFlagsLoading ||
    isCompanyLoading ||
    isPermissionsLoading;
  // are any of the V2 auth requests, in-flight for the first time?
  const v2IsInitialLoading =
    (!userErrorUpdateCount && isUserInitialLoading) ||
    isGlobalFeatureFlagsInitialLoading ||
    isCompanyFeatureFlagsInitialLoading ||
    isCompanyInitialLoading ||
    isPermissionsInitialLoading;

  const getWhichAuthVersion = ():
    | "unknown"
    | AuthenticatedContextAuthVersionType[] => {
    if (!isAuthenticated) {
      return "unknown";
    }

    // is authenticated user in both auth systems?
    if (customClaims && legacyUser && user) {
      return ["v1", "v2"];
    }

    // is authenticated user only in legacy auth system?
    if (customClaims && legacyUser && !user) {
      return ["v1"];
    }

    // is authenticated user in the new auth system alone?
    if (customClaims && !legacyUser && user) {
      return ["v2"];
    }

    return "unknown";
  };

  const getClaimsHolistic = () => {
    const authSystem = getWhichAuthVersion();

    if (authSystem === "unknown") {
      return { user: {}, company: {} };
    }

    if (authSystem.length === 1) {
      if (authSystem[0] === "v1") {
        return {
          user: {
            id: customClaims?.user.id,
          },
          company: {
            id: customClaims?.company.id,
          },
        };
      } else if (authSystem[0] === "v2") {
        return {
          user: {
            id: customClaims?.user.id,
          },
          company: {
            id: customClaims?.company?.id,
          },
        };
      }
    }

    const userId = customClaims?.user?.id ?? user?.id ?? legacyUser?.id;
    const companyId = customClaims?.company?.id ?? company?.id;

    return {
      user: {
        id: userId,
      },
      company: {
        id: companyId,
      },
    };
  };

  const getIsLoadingHolistic = () => {
    const authSystem = getWhichAuthVersion();

    if (authSystem === "unknown" || v1IsInitialLoading || v2IsInitialLoading) {
      return true;
    }

    if (authSystem.length === 1) {
      if (authSystem[0] === "v1") {
        return v1IsLoading;
      } else if (authSystem[0] === "v2") {
        return v2IsLoading;
      }
    }

    const authVersionToLoadingStates: Record<
      AuthenticatedContextAuthVersionType,
      boolean
    > = {
      v1: v1IsLoading,
      v2: v2IsLoading,
    };

    return authSystem.some((version) => authVersionToLoadingStates[version]);
  };

  const login = useCallback(() => {
    ExceptionUtils.registerLifecycleEvent({
      category: "app",
      type: "info",
      message: "Redirecting to login",
    });
    auth0.loginWithRedirect({
      authorizationParams: {
        login_hint: user?.email ?? legacyUser?.email ?? auth0Claims?.email,
        audience: config.API_ID,
        scope: "openid profile email offline_access",
      },
    });
  }, [auth0, user, legacyUser, auth0Claims]);

  const logout = useCallback(() => {
    queryClient.removeQueries({ queryKey: ["auth"] });

    // specific to the V1 auth system and Redux from the legacy part of the codebase
    localStorage.removeItem("user_trait");
    localStorage.removeItem("user_id");

    ExceptionUtils.teardown();
    auth0.logout({
      logoutParams: {
        returnTo: `${window.location.origin}/app/logout`,
      },
    });
  }, [auth0, queryClient]);

  const authorizeDomainLayer = async () => {
    try {
      await authorize(accessToken ?? (await auth0?.getTokenSilently()));
    } catch (error) {
      console.error(
        "AuthenticatedContextProvider: Error authorizing domain layer: ",
        error
      );
      auth0?.loginWithRedirect({
        authorizationParams: {
          audience: config.API_ID,
          scope: "openid profile email offline_access",
        },
      });
    }

    ExceptionUtils.registerLifecycleEvent({
      category: "app",
      type: "info",
      message: "Bootstrapped @validereinc/domain module",
    });
  };

  const switchCompany = async (companyId: string) => {
    // View for more context https://community.auth0.com/t/user-switch-between-organization/62609
    const newAccessToken = await auth0?.getTokenSilently({
      authorizationParams: { company_id: companyId },
      cacheMode: "off",
    });
    setAccessToken(newAccessToken);
    window.location.reload();
  };

  const extractClaimsAndAuthenticatedUser = async () => {
    const claims = await auth0.getIdTokenClaims();
    const user = await auth0.getUser();

    return { claims, user };
  };

  const extractCustomClaims = <T,>(customClaims: string) => {
    if (typeof customClaims !== "string") {
      return customClaims;
    }

    // assume it's a Base64 JSON string and attempt to decode
    try {
      const decodedCustomClaims = window.atob(customClaims);

      return JSON.parse(decodedCustomClaims) as T;
    } catch (err: unknown) {
      if (
        (err as DOMException)?.name !== "InvalidCharacterError" &&
        (err as SyntaxError)?.name !== "SyntaxError"
      ) {
        throw err;
      }
    }

    // attempt to just JSON parse
    try {
      return JSON.parse(customClaims) as T;
    } catch (err: unknown) {
      if ((err as SyntaxError)?.name !== "SyntaxError") {
        throw err;
      }
    }

    // unrecognized custom claims
    console.error(
      "AuthenticatedContextProvider: Could not recognize custom claims format."
    );
    return null;
  };

  useEffect(() => {
    const load = async () => {
      const entitlementsUrl = "https://validere.com/entitlements";

      const decodedJwtPayload: DecodedJwtPayloadType = accessToken
        ? jwtDecode(accessToken)
        : {};

      await authorizeDomainLayer();

      const { claims, user } = await extractClaimsAndAuthenticatedUser();

      const embeddedCustomClaims = extractCustomClaims<CustomClaimsType>(
        decodedJwtPayload?.[`${entitlementsUrl}`] ??
          claims?.[`${entitlementsUrl}`] ??
          user?.[`${entitlementsUrl}`]
      );
      const isAuthSuccessful = await auth0.isAuthenticated();

      setIsAuthenticated(isAuthSuccessful);
      ExceptionUtils.registerAuthenticatedSessionHandshake({
        id: claims?.sub ?? user?.sub ?? "",
        isEmailVerified:
          claims?.email_verified ?? user?.email_verified ?? false,
        sessionStart: claims?.iat ?? new Date().getTime() / 1000,
      });
      ExceptionUtils.registerLifecycleEvent({
        category: "session",
        type: "info",
        message: "Session verified and handshake complete",
      });

      if (!isPlainObject(claims)) {
        return;
      }

      if (embeddedCustomClaims) {
        setCustomClaims(embeddedCustomClaims);
      }

      if (claims) {
        setAuth0Claims(claims);
      }
    };

    setIsLoading(true);

    load()
      .catch((err) => console.error("AuthenticatedContextProvider:", err))
      .finally(() => setIsLoading(false));
  }, [auth0, accessToken]);

  // IMPROVE: this effect is a by-pass to sync new ctx based auth work with legacy Redux work. Remove when Redux is dropped.
  useEffect(() => {
    if (!legacyUser) {
      return;
    }

    UserService.reportLogin();
    fetchUserProfile(legacyUser ? { legacyUserBackup: legacyUser } : {})(
      store.dispatch
    );
  }, [legacyUser]);

  // IMPROVE: this effect is a by-pass to sync new ctx based auth work with legacy Redux work. Remove when Redux is dropped.
  useEffect(() => {
    if (!legacyFeatureFlags) {
      return;
    }

    fetchFeatureFlags(legacyFeatureFlags)(store.dispatch);
  }, [legacyFeatureFlags]);

  // IMPROVE: this effect is a by-pass to sync new ctx based auth work with legacy Redux work. Remove when Redux is dropped.
  useEffect(() => {
    if (!legacyPermissions) {
      return;
    }

    fetchUserPermissions(legacyPermissions)(store.dispatch);
  }, [legacyPermissions]);

  return (
    <AuthenticatedContext.Provider
      value={{
        appVersion,
        activeAuthVersion: getWhichAuthVersion(),
        isAuthenticated,
        isLoading: getIsLoadingHolistic(),
        claims: getClaimsHolistic(),
        switchCompany,
        login,
        logout,
        /**
         * @deprecated reflects old monolith authorization system (Elixir API, OpsHub).
         */
        v1: {
          claims: customClaims,
          user: legacyUser,
          isLoading: v1IsLoading,
          featureFlags: legacyFeatureFlags,
          permissions: legacyPermissions,
          checkIsFeatureEnabled:
            legacyCheckIsFeatureEnabled(legacyFeatureFlags),
          checkHasPermission: legacyCheckHasPermission(legacyPermissions),
        },
        v2: {
          claims: customClaims,
          isLoading: v2IsLoading,
          featureFlags: globalFeatureFlags?.data ?? [],
          checkIsFeatureEnabled: createAuthorizationEvaluator(
            globalFeatureFlags?.data.filter((flag) =>
              Boolean(flag.isEnabled)
            ) ?? []
          ),
          userInfo: {
            user,
            permissions: permissions,
            checkHasPermissions: createAuthorizationEvaluator(permissions),
          },
          companyInfo: {
            company,
            featureFlags: companyFeatureFlagsWithDetails,
            checkIsFeatureEnabled: createAuthorizationEvaluator(
              companyFeatureFlagsWithDetails?.filter((flag) =>
                Boolean(flag.isEnabled)
              ) ?? []
            ),
          },
        },
      }}
    >
      {children}
    </AuthenticatedContext.Provider>
  );
};
