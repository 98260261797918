import { useQueueUnique } from "#src/hooks/useQueue";
import { Banner, IconLoading } from "@validereinc/common-components";
import {
  ResourceDefinitions,
  Resources,
  ResourceType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useMemo } from "react";
import styles from "./TemplatedConfigurationRunStatus.module.scss";
import { useGetOneTemplatedConfigurationRun } from "../../../components/hooks/adapters/useTemplatedConfigurations";
import { linkToEquipmentDetail } from "#src/Routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";

export type TemplatedConfigurationQueueType = {
  job: {
    id: string;
    templateName: string;
  };
  resourceDetails: {
    type?: {
      id: string;
      name: string;
    };
  };
};

const cx = classNames.bind(styles);

export const templatedConfigurationRunQueueName = (
  primaryResourceType: ResourceType
) => `queue-templated-configuration-run-${primaryResourceType}`;

export const TemplatedConfigurationRunStatus = ({
  details: {
    job: { id: jobId, templateName },
    resourceDetails: { type: resourceCreationType },
  },
  primaryResourceType,
  onDismiss,
}: {
  details: TemplatedConfigurationQueueType;
  primaryResourceType: ResourceType;
  onDismiss: () => void;
}) => {
  const maxRetries = 3;

  const primaryResourceLabel =
    ResourceDefinitions[primaryResourceType].label.singular;

  const { data, status, failureCount } = useGetOneTemplatedConfigurationRun(
    {
      id: jobId,
      meta: { templated_configuration_name: templateName },
    },
    {
      enabled: !!jobId,
      refetchInterval: (data) =>
        data?.data?.status === "submitted" || data?.data?.status === "started"
          ? 3000
          : false,
      refetchIntervalInBackground: true,
      retry: maxRetries,
      retryDelay: (attempt) => attempt * 1000,
    }
  );

  const job = data?.data;

  const jobBannerVariant = useMemo(() => {
    switch (job?.status) {
      case "success":
      case "partial":
        return "success";
      case "failed":
        return "error";
      case "submitted":
        return "info";
      case "started":
        return "info";
    }
    return "generic";
  }, [job?.status]);

  const primaryResourceSuccessfullyCreated = job?.resources.find(
    (resource) => !!resource.is_primary
  );

  const jobStatusTitleText = useMemo(() => {
    const defaultText = `${ResourceDefinitions.templated_configuration_run.label.singular} in progress...`;
    if (!job) return defaultText;
    const resourcesSuccessfullyCreated =
      Array.isArray(job?.resources) && job.resources.length - 1 > 0
        ? job.resources.length - 1
        : 0;

    const otherCreatedResourcesLabel =
      resourcesSuccessfullyCreated > 0
        ? ` and ${resourcesSuccessfullyCreated} associated resource${resourcesSuccessfullyCreated > 1 ? "s" : ""}`
        : "";

    switch (job?.status) {
      case "submitted":
        return `Creating a new ${resourceCreationType?.name ?? primaryResourceLabel} using ${ResourceDefinitions.templated_configuration.label.singular} "${job?.templated_configuration.display_name}"...`;
      case "started":
        return `Creating a new ${resourceCreationType?.name ?? primaryResourceLabel} using ${ResourceDefinitions.templated_configuration.label.singular} "${job?.templated_configuration.display_name}"...`;
      case "success":
        return `Successfully created ${primaryResourceLabel} "${primaryResourceSuccessfullyCreated?.name}"${otherCreatedResourcesLabel} using ${ResourceDefinitions.templated_configuration.label.singular} "${job?.templated_configuration.display_name}".`;
      case "partial":
        return `Partially created a new ${resourceCreationType?.name ?? primaryResourceLabel} using ${ResourceDefinitions.templated_configuration.label.singular} "${job?.templated_configuration.display_name}".`;
      case "failed":
        return `Failed to create ${primaryResourceLabel}`;
      default:
        return defaultText;
    }
  }, [job]);

  const withinRetries = failureCount < maxRetries;
  const isLoading = withinRetries && status === "loading";
  const isError = (!withinRetries && status === "error") || status === "error";
  const isInProgress =
    job && (job.status === "submitted" || job.status === "started");

  if (isLoading || isError || !job) {
    return (
      <Banner
        className={cx("banner")}
        variant={isLoading ? "generic" : "warning"}
        titleText={
          isLoading
            ? `Fetching ${ResourceDefinitions.templated_configuration_run.label.plural} details...`
            : `Couldn't fetch ${ResourceDefinitions.templated_configuration_run.label.plural} details.`
        }
        icon={isLoading ? <IconLoading speed="slow" /> : undefined}
        isDismissable={!isLoading}
        onDismiss={() => onDismiss?.()}
      />
    );
  }

  const linkToPrimaryResource =
    primaryResourceSuccessfullyCreated?.type === Resources.EQUIPMENT &&
    primaryResourceSuccessfullyCreated?.id
      ? linkToEquipmentDetail(primaryResourceSuccessfullyCreated.id)
      : "";

  return (
    <Banner
      className={cx("banner")}
      variant={jobBannerVariant}
      titleText={jobStatusTitleText}
      icon={isInProgress ? <IconLoading speed="fast" /> : undefined}
      actionContent={
        <>
          {job.status === "success" && linkToPrimaryResource && (
            <RoutingLink to={linkToPrimaryResource}>
              View {primaryResourceLabel} & Resource Details
            </RoutingLink>
          )}
        </>
      }
      isDismissable
      onDismiss={() => onDismiss?.()}
    />
  );
};

export const TemplatedConfigurationRunStatusWithQueue = ({
  primaryResourceType,
}: {
  primaryResourceType: ResourceType;
}) => {
  const [canUserReadTemplatedConfigurationRuns] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configuration_runs:read",
  });

  const [queue, setQueue] = useQueueUnique<TemplatedConfigurationQueueType>(
    [],
    templatedConfigurationRunQueueName(primaryResourceType),
    (tx) => tx.job.id
  );

  const handleDismiss = (id: string) => {
    setTimeout(() => {
      setQueue(queue.cutById(id));
    }, 500);
  };

  if (!canUserReadTemplatedConfigurationRuns) return null;

  return (
    <>
      {queue.size()
        ? queue.getAsArray().map((item) => (
            <TemplatedConfigurationRunStatus
              key={item.job.id}
              details={item}
              primaryResourceType={primaryResourceType}
              onDismiss={() => handleDismiss(item.job.id)}
            />
          ))
        : null}
    </>
  );
};
