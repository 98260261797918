import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { HomeRoute } from "#src/routes/home";
import { Page } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import { EmissionsHomeFilterPanel } from "./EmissionsHomeFilterPanel";
import { EmissionsHomeMapPanel } from "./EmissionsHomeMapPanel";
import { FacilityEmissionsByPollutantDataTablePanel } from "./FacilityEmissionsByPollutantDataTablePanel";
import { PollutantPieChartPanel } from "./PollutantPieChartPanel";
import { TotalEmissionsPanel } from "./TotalEmissionsPanel";
import styles from "./emission-home.module.scss";
import { useBreadcrumbsFromRoute } from "#src/utils/route";

const cx = classNames.bind(styles);

export const EmissionsHomePage = () => {
  const { filterConfigStorageKey } = useStorageKey("home-emissions");
  const {
    v2: {
      userInfo: { user },
      companyInfo: { company },
    },
  } = useAuthenticatedContext();

  const [breadcrumbs] = useBreadcrumbsFromRoute(HomeRoute);
  const homePageTitle = user?.name
    ? `Welcome, ${user?.name.split(" ")[0]}`
    : "Welcome";

  return (
    <Page
      title={homePageTitle}
      breadcrumbs={breadcrumbs}
      category={company?.name ?? ""}
    >
      <EmissionsHomeFilterPanel
        filterConfigStorageKey={filterConfigStorageKey}
      />
      <div className={cx("grid")}>
        <EmissionsHomeMapPanel
          filterConfigStorageKey={filterConfigStorageKey}
        />
        <TotalEmissionsPanel filterConfigStorageKey={filterConfigStorageKey} />
        <PollutantPieChartPanel
          filterConfigStorageKey={filterConfigStorageKey}
        />
        <FacilityEmissionsByPollutantDataTablePanel
          filterConfigStorageKey={filterConfigStorageKey}
        />
      </div>
    </Page>
  );
};
