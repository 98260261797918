import { getPermissionDisplayLabel } from "#src/contexts/AuthenticatedContext.helpers";
import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import { RoleCreateFormSchema } from "#src/routes/settings/roles-and-permissions/roles/create";
import {
  DropdownInput,
  Form,
  Panel,
  useForm,
} from "@validereinc/common-components";
import { PermissionsAdapter } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useCallback, useEffect } from "react";
import styles from "./CreateRolePermissionsStep.module.scss";

const cx = classNames.bind(styles);

export const CreateRolePermissionsStep = () => {
  const { currentStep, getStep, updateStep } = useMultiStepFormContext();
  const stepDetails = getStep(3);
  const form = useForm({
    defaultValues: stepDetails?.defaultValues ?? {},
  });

  const memoizedGetFormState = useCallback(
    () => ({
      isSubmitting: form.formState.isSubmitting,
      isValid: form.formState.isValid,
    }),
    [form.formState.isSubmitting, form.formState.isValid]
  );

  useEffect(() => {
    updateStep(3, {
      getValues: () => form.getValues(),
      getFormState: memoizedGetFormState,
    });
  }, [form]);

  const formKeys = RoleCreateFormSchema.keyof().Enum;

  if (currentStep !== 3) {
    return null;
  }

  return (
    <Panel title={stepDetails?.label}>
      <Form {...form}>
        <div className={cx("detailsStepContainer")}>
          <DropdownInput
            label="Permissions"
            name={formKeys.selected_permissions}
            labelKey="label"
            valueKey="name"
            onFetchData={async (payload) => {
              let { data } = await PermissionsAdapter.getList({
                ...payload,
                filters: {
                  name: payload.searchTerm,
                },
              });

              // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
              if (Array.isArray(payload.value)) {
                data = data.filter((d) => payload.value.includes(d.name));
              }

              return data.map((d) => ({
                ...d,
                label: getPermissionDisplayLabel(d.name),
              }));
            }}
            isMulti
            isFluid
          />
        </div>
      </Form>
    </Panel>
  );
};
